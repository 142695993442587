import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from './UI/Icon';
import Spinner from './LoadingSpin';
import styles from './controls.less';

const log = require('debug')('CRM:Component:Common:Loading');

const Loading = (props) => {
  const { t } = useTranslation();
  log('render');
  const { error, success, info, text: textProps, className, fullHeight, styles: stylesProps } = props;

  let text = error || success || info || textProps;

  if (!text && text !== '') {
    text = t('loadingText');
  }

  const classes = classNames(styles.loadingContainer, className, {
    [styles.loadingFullHeight]: fullHeight,
    [styles.loadingError]: error,
    [styles.loadingSuccess]: !error && success,
  });

  let icon;

  if (error) {
    icon = <Icon type="icon interface-57" />;
  } else if (success) {
    icon = <Icon type="icon status-17" />;
  } else {
    icon = <Spinner />;
  }

  return (
    <div className={classes} style={stylesProps}>
      {icon}
      {_.isString(text) ? <span>{` ${text}`}</span> : null}
    </div>
  );
};

Loading.propTypes = {
  text: PropTypes.string,
  fullHeight: PropTypes.bool,
  info: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
  styles: PropTypes.object,
  className: PropTypes.string,
};

export default Loading;
