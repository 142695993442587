import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './dataTypes.less';

const AddressField = (props) => {
  let val = props.value;
  val = val && val.get('value');
  if (val && val.length > 200) {
    val = `${val.substr(0, 200)}...`;
  }

  return <span className={cn(props.containerClassName, styles.simpleField)}>{val || ' '}</span>;
};

AddressField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
};

export default AddressField;
