import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import _ from 'lodash';

import ResultList from './resultComponents/ResultList';
import Description from './resultComponents/Description';

import styles from '../styles/styles.less';

const TabRecordsBatchResult = (props) => (
  <div className={styles.resultTabContainer}>
    <Description
      catalogId={props.catalogId}
      sectionId={props.sectionId}
      sceneId={props.sceneId}
      records={props.records}
      wrapperClassname={styles.description}
      descriptionText={props.descriptionText}
    />
    <ResultList
      catalogId={props.catalogId}
      sectionId={props.sectionId}
      sceneId={props.sceneId}
      wrapperClassname={styles.resultList}
    />
  </div>
);

TabRecordsBatchResult.propTypes = {
  records: ImmutablePropTypes.map,
  catalogId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  sceneId: PropTypes.string.isRequired,
};

export default TabRecordsBatchResult;
