import React, { useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Row } from 'antd';
import tinyColor from 'tinycolor2';

import colorText from '../../../../utils/colorFromString';

import Badge from '../../../common/UI/Badge';
import Icon from '../../../common/UI/Icon';

import styles from './fullMenu.less';
import SectionSettings from '../../AppBody/Section/SectionSettings';

const SectionItem = (props) => {
  const { item, selected, opened, onClick } = props;

  const id = item.get('id');
  const name = item.get('name') || item.get('title'); // title is set for $favorite section;
  const badge = item.get('newChats');
  const className = item.get('className') || null;

  const getColor = () => {
    if (className) {
      return 'inherit';
    }
    let color = colorText.colorFromString(name.substr(0, 1));
    color = tinyColor(color).toHsl();

    let colorNice = {};
    colorNice.h = color.h; // hue
    colorNice.s = 1; // 0.85 + (color.s - 0.5) * 0.1; // saturation
    colorNice.l = 0.9; // lightness

    colorNice = tinyColor(colorNice).setAlpha(0.2).toHexString();
    return colorNice;
  };

  const onSettingsClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const hiseSectionsSettings = _.includes(['addButton', '$favorite'], id);

  return (
    <div className={styles.section} /* style={{background: getColor()}} */>
      <Row
        className={cn(styles.title, className, {
          [styles.opened]: opened,
          [styles.selected]: selected && !opened,
          [styles.addSection]: id === 'addButton',
        })}
        title={name}
        onClick={onClick}
      >
        <Badge dot={badge === true} count={badge} className={styles.badge}>
          <span className={styles.text}>{name}</span>
        </Badge>
        <Icon
          className={styles.arrow}
          type={`icon ${opened ? 'arrows-chevron-medium-thin-4-04' : 'arrows-chevron-medium-thin-4-01'}`}
        />

        {!hiseSectionsSettings && (
          <div className={styles.sectionSettingsWrapper} onClick={onSettingsClick}>
            <SectionSettings section={item} className={styles.sectionSettings} />
          </div>
        )}
      </Row>
      <Row className={cn(styles.sectionBody, { [styles.sectionBodyOpened]: opened })}>{props.children}</Row>
    </div>
  );
};

export default SectionItem;
