import React, { useCallback } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import Icon from '../../../../../common/UI/Icon';
import TextMessage from '../../../../../Record/RecordBody/chatTab/Messages/Message/Text';
import sceneActions from '../../../../../../actions/sceneActions';
import { formatDate, formatTime } from '../../../../../../utils/formatDate';

import styles from './chatItem.less';

const getCountAttachment = (size, t) =>
  `${size} ${t('record.chat.message.attachments.count', {
    count: size,
  })}`;

const ChatItem = ({ params, message, icon, title, isActive, me, t }) => {
  const isToday = moment(message.createdDate).isSame(moment(), 'day');
  const date = isToday ? formatTime(message.createdDate) : formatDate(message.createdDate);
  // Есть особенность со сценариями: у сообщения отправленного компонентой 'создать запись' может не быть автора
  const author =
    _.get(message, ['author', 'recordId']) === me.get('id')
      ? `${t('chats.message.titleAuthorIsMe')}: `
      : `${_.get(message, ['author', 'recordTitle'], `${t('chats.message.unknownAuthorTitle')}`)}: `;
  const { attachments } = message;
  const text = message.text
    ? message.text
    : attachments && attachments.length
      ? getCountAttachment(attachments.length, t)
      : '';
  const { newMessage } = message;

  const openRecord = () => {
    sceneActions.openRecord({
      ...params,
      defaultTab: 'Chat',
    });
  };

  return (
    <div
      className={classNames(styles.chatItemContainer, {
        [styles.chatItemContainerActive]: isActive,
      })}
      onClick={openRecord}
    >
      <div className={styles.chatItemRecordInfo}>
        <Icon className={styles.chatItemRecordIcon} type={`icon ${icon}`} />
        <span className={styles.chatItemRecordTitle}>{title}</span>
        <span
          className={classNames(styles.chatItemRecordMessageDate, {
            [styles.chatItemRecordMessageDateNewMessage]: newMessage,
          })}
        >
          {date}
        </span>
      </div>
      <div className={styles.chatItemMessageInfo}>
        <TextMessage
          className={styles.chatItemMessageText}
          text={author + text}
          updatedDate={message.updatedDate}
          highlight={false}
        />
      </div>
    </div>
  );
};

export default ChatItem;
