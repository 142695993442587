import React from 'react';
import { Modal } from 'antd';
import cn from 'classnames';
import VideoPlayer from '../../UI/VideoPlayer';

import styles from './styles.less';

const VideoViewer = ({
  url,
  onCancel,
  visible,
  controls = true,
  modalWithd = '83%',
  closable = true,
  playing = true,
}) => (
  <Modal
    onCancel={onCancel}
    className={styles.modal}
    closable={closable}
    open={visible}
    width={modalWithd}
    footer=""
    style={{
      transition: 'width 0.5s ease 0s',
      paddingBottom: '20px',
      top: '30px',
    }}
    wrapClassName={cn(styles.modal)}
  >
    <div className={styles.wideoWrapper}>
      <VideoPlayer
        // playing={playing}
        className="react-player"
        url={url}
        width="800px"
        style={{ display: 'block', backgroundColor: 'black' }}
        controls={controls}
      />
    </div>
  </Modal>
);

export { VideoViewer };
