import React, { useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import classNames from 'classnames';
import _ from 'lodash';
import guid from 'guid';

import { mentionsToHtml } from '../../../../../../utils/mentionsToHtml';
import { defineLink } from '../../../../../../utils/defineLink';

import styles from './message.less';

const Text = ({
  text,
  updatedDate,
  className,
  // всегда подсвечиваем упоминания, кроме как в окошке чатов
  highlight = true,
  ...props
}) => {
  const [mentionsHtml, setMentionsHtml] = useState('');
  const [mentions, setMentions] = useState(false);
  const [url, setUrl] = useState(false);
  const [id, setId] = useState(null);
  const [messageGuid, setMessageGuid] = useState('');

  const openRecord = () => {
    const ids = id.split(':');
    // to-do: replace to "$users"
    const params = {
      catalogId: ids[0],
      recordId: ids[1],
    };
    if (props.openRecord) {
      props.openRecord(params);
    }
  };

  useEffect(() => {
    // Здесь нужен уникальный идентификатор для того, чтобы подхватить конкретное сообщение
    // Иначе мы будем навешивать слушатели на одни и те же ноды, а на некоторые вообще не навесить
    // Потому что, id вида - "catalogId:recordId" могут быть одинаковы у нескольких сообщений(если мы например несколько раз упомянем одну и ту же запись несколько раз)
    // Добавляем префикс к началу, так как селектор не может начинаться с числа
    const messageGuid = `mess${guid.raw()}`;
    const { formatedText, isMentions, id } = mentionsToHtml(text, highlight, messageGuid);
    const { textWithLink, isLink } = defineLink(formatedText);
    setMentionsHtml(textWithLink);
    setUrl(isLink);
    setMentions(isMentions);
    setMessageGuid(messageGuid);
    if (!_.isNil(id) && id) {
      setId(id);
    }
  }, [text]);

  useEffect(() => {
    if (!_.isNull(id) && highlight && !_.isEmpty(messageGuid)) {
      const myDiv = document.querySelector(`.${messageGuid}`);

      if (myDiv && myDiv.getAttribute('listener') !== 'true') {
        myDiv.addEventListener('click', openRecord);
      }

      // При unmount проводим очистку слушателей
      return () => {
        myDiv.removeEventListener('click', openRecord);
      };
    }
  }, [id]);

  const theObj = {
    __html: sanitizeHtml(mentionsHtml, {
      allowedTags: url ? ['xxx', 'a'] : ['xxx'],
      allowedAttributes: {
        xxx: ['class', 'id'],
        a: ['href', 'target'],
      },
      disallowedTagsMode: 'escape',
    }),
  };

  if (mentions || url) {
    return (
      <span
        dangerouslySetInnerHTML={theObj}
        className={classNames(styles.msgText, className, {
          [styles.msgTextUpdateDate]: !!updatedDate,
        })}
      />
    );
  }
  return (
    <span
      className={classNames(styles.msgText, className, {
        [styles.msgTextUpdateDate]: !!updatedDate,
      })}
    >
      {mentionsHtml}
    </span>
  );
};

export default Text;
