import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import Icon from '../../common/UI/Icon';
import ButtonTransparent from '../../common/UI/ButtonTransparent';

import filterActions from '../../../actions/filterActions';

import styles from './filterNewMessages.less';

const FilterNewMessages = ({ recordsCountNewMessages, sceneId, viewId, titleNewMessages, titleAll }) => {
  const [filteredNewMessages, setFilteredNewMessages] = useState(false);
  useEffect(() => {
    if (recordsCountNewMessages < 1 && filteredNewMessages) {
      filterActions.filterByNewMessages(sceneId, false, {
        viewId,
      });
    }
  }, [recordsCountNewMessages]);
  const title = filteredNewMessages ? titleAll : titleNewMessages;

  const filterNewMessages = () => {
    if (recordsCountNewMessages !== 0) {
      filterActions.filterByNewMessages(sceneId, !filteredNewMessages, {
        viewId,
      });
      setFilteredNewMessages((prev) => !prev);
    }
  };

  return (
    <ButtonTransparent
      className={cn([styles.filterNewMessagesButton], {
        [styles.filterNewMessagesButtonActive]: filteredNewMessages,
        [styles.filterNewMessagesHasNew]: !!recordsCountNewMessages,
      })}
      title={title}
      onClick={filterNewMessages}
      disabled={recordsCountNewMessages == 0}
    >
      <Icon type="icon communication-91" className={styles.filterNewMessagesIcon} />
      <span className={styles.filterNewMessagesCount}>
        {recordsCountNewMessages == 0 ? '' : recordsCountNewMessages}
      </span>
    </ButtonTransparent>
  );
};

export default FilterNewMessages;
