import _ from 'lodash';
import React from 'react';

import styles from '../styles/helper.less';

export const TipList = ({ title, items, switchToTutorial }) => (
  <div className={styles.subGroupContainer}>
    <p>{title}</p>
    <ul>
      {items &&
        items.map((item) => (
          <li className={styles.backlink} key={item.title} onClick={() => switchToTutorial(item.code)}>
            {item.title}
          </li>
        ))}
    </ul>
  </div>
);
