import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Input } from 'antd';
import { withTranslation } from 'react-i18next';

import { TIME, DATE, WEEK, MONTH, QUARTER, YEAR } from '../../../../../configs/dateFieldSubTypes';
import { formats, subFormats, timeFormats } from '../../../../../configs/datePickerFormats';
import styles from './controls.less';
import WrappedDatePicker from '../../WrappedDatePicker';
import WrappedTimePicker from '../../WrappedTimePicker';

const log = require('debug')('CRM:Component:Record:DateField');

const API_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
const API_FORMAT_DATE = 'YYYY-MM-DD';
const API_FORMAT_TIME = 'HH:mm:ssZ';

const DateControl = (props) => {
  const { value, editable, config, onChange, onEndEditing, autoFocus, fieldId, t } = props;
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  useEffect(() => {
    if (value) {
      setDate(moment(value).format(API_FORMAT_DATE));
      setTime(moment(value).format(API_FORMAT_TIME));
    }
  }, [value]);
  const onChangeHandler = (picker, val) => {
    if (picker === 'date' && val) {
      if (type === WEEK) {
        val.startOf('week');
      } else if (type === MONTH) {
        val.startOf('month');
      } else if (type === QUARTER) {
        val.startOf('quarter');
      } else if (type === YEAR) {
        val.startOf('year');
      }
      setDate(val.format(API_FORMAT_DATE));
      if (time) {
        val = `${val.format(API_FORMAT_DATE)}T${time}`;
      }
    } else if (picker === 'date' && val === null) {
      setDate('');
      val = null;
    }

    if (picker === 'time' && val) {
      setTime(val.format(API_FORMAT_TIME));
      if (date) {
        val = `${date}T${val.format(API_FORMAT_TIME)}`;
      }
    } else if (picker === 'time' && val === null) {
      val = `${date}T00:00:00`;
      setTime('');
    }

    val = val !== null ? moment(val).format(API_FORMAT) : val;
    log('Changed field %s value', fieldId, val);
    onChange && onChange(val);
    onEndEditing && onEndEditing(val);
  };

  const selectTime = (value) => {
    const val = moment(value).format(API_FORMAT);
    setTime(value.format(API_FORMAT_TIME));
    onChange && onChange(val);
    onEndEditing && onEndEditing(val);
  };

  const dateIsValid = moment(value || '').isValid();
  const type = config.get('type');
  const withTime = type === TIME;

  const format = formats[type] || 'L';

  const subLocaleFormat = props.i18n.language === 'ru' ? 'DD,MM,YYYY' : 'MM,DD,YYYY';

  const subFormat = subFormats[type] || [];

  const possibleFormats = [format, ...subFormat, subLocaleFormat];

  let dateComponent;
  if (!editable) {
    const formattedDateValue = dateIsValid ? moment(value).format(format) : value;

    dateComponent = <Input readOnly value={formattedDateValue} className={styles.datePickerReadOnly} />;
  } else {
    const pickers = {
      [TIME]: 'date',
      [DATE]: 'date',
      [WEEK]: 'week',
      [MONTH]: 'month',
      [QUARTER]: 'quarter',
      [YEAR]: 'year',
    };
    const picker = pickers[type] || 'date';
    dateComponent = (
      <WrappedDatePicker
        picker={picker}
        value={value ? moment(value) : null}
        format={possibleFormats}
        placeholder={t(`fieldTypes.date.placeholder.${withTime ? DATE : type}`)}
        autoFocus={autoFocus}
        className={styles.datePicker}
        onChange={(val) => onChangeHandler('date', val)}
      />
    );
  }

  // время
  let timeComponent;
  if (withTime) {
    const formattedTimeValue = dateIsValid ? moment(value).format('LTS') : value;
    timeComponent = !editable ? (
      <Input readOnly value={formattedTimeValue} className={styles.timePickerReadOnly} />
    ) : (
      <WrappedTimePicker
        minuteStep={5}
        hideDisabledOptions
        value={value ? moment(value) : null}
        className={styles.timePicker}
        popupClassName={styles.timePickerPopup}
        format={timeFormats}
        onChange={(val) => onChangeHandler('time', val)}
        placeholder={t(`fieldTypes.date.placeholder.${type}`)}
        onSelect={selectTime}
      />
    );
  }

  // сколько прошло
  let pasteTime = moment(value, API_FORMAT).fromNow();
  if (!withTime && dateIsValid) {
    pasteTime = '';
  }

  return (
    <div className={styles.dateContainer}>
      {dateComponent}
      {timeComponent}
      {value && (
        <span className={cn(styles.oneLine, styles.subInfo)} title={pasteTime}>
          {pasteTime}
        </span>
      )}
    </div>
  );
};

DateControl.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
};

export default withTranslation()(DateControl);
