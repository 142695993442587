import React from 'react';
import Immutable from 'immutable';
import _ from 'lodash';

import FIELD_TYPES from '../../configs/fieldTypes';
import { Field } from './BaseClass.js';

export default class Button extends Field {
  constructor() {
    super();
  }

  static type = FIELD_TYPES.BUTTON;

  static components = {
    control: require('../../components/common/UI/ControlList/controls/Button').default,
  };

  static getComponent = (type) => Button.components[type];

  static getCanComponentExpandWidthOrHeight = (value) => {};

  static createComponent = (field, value, type) => {
    const Component = Button.components[type];
    return ({ containerClassName }) => {
      if (Button.isEmpty(value)) {
        return null;
      }
      return <Component config={field.get('config')} value={value} containerClassName={containerClassName} />;
    };
  };

  static convertFilterToRecordValue = () => undefined;

  static getDefaultValue = (field) => {
    const defaultValue = field.getIn(['config', 'defaultEmptyValue']);
    return defaultValue;
  };

  static validateRequired = (value) => !value;

  static validateValue = (field, value) => {
    if (value == null) {
      return true;
    }
    return false;
  };

  static isEmpty = (value) => !value;

  static getEmptyValue = () => null;

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(['config', 'type']);
    switch (type) {
      default:
        return false;
    }
  };

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}
