import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { message } from 'antd';

import { withTranslation } from 'react-i18next';
import appState from '../../appState';
import recordActions from '../../actions/recordActions';
import sceneActions from '../../actions/sceneActions';
import apiActions from '../../actions/apiActions';
import Loading from '../common/Loading';
import getLink from '../common/router/getLink';
import Prompt from '../common/router/Prompt';
import getRouteParams from '../common/router/getRouteParams';
import RecordHeader from './RecordHeader';
import RecordBody from './RecordBody';
import { connect } from '../StateProvider';
import routes from '../../routes';
import { checkAccessOnObject } from '../../utils/rights';
import PRIVILEGE_CODES from '../../configs/privilegeCodes';
import RESOURCE_TYPES from '../../configs/resourceTypes';
import SCENE_CONTAINER from '../../configs/sceneContainer';
import NavRedirect from '../common/router/Redirect';
import { confirm } from '../common/Modal';

import styles from './record.less';

class Record extends PureComponent {
  static propTypes = {
    catalogId: PropTypes.string,
    sceneId: PropTypes.string,
    recordId: PropTypes.string,
    onRefresh: PropTypes.func,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    isWebForm: PropTypes.bool,
    isLoading: PropTypes.func,
  };

  constructor() {
    super();
    this.state = {
      newRecordCreated: false,
    };
  }

  componentDidMount() {
    const { catalogId, record } = this.props;

    catalogId && this.getCatalog(catalogId);
    if (record) {
      this.setState({ initialValues: record.get('values') });
    }
  }

  componentDidUpdate(prevProps) {
    const { catalogId, isActive, record, shouldClose, sceneId } = this.props;

    if (catalogId !== prevProps.catalogId) {
      catalogId && this.getCatalog(catalogId);
    }

    /* тк компонент не анмаунтится, а сменяются пропсы, смотрим на смену активной сцены */
    const shouldRefreshInitialValues =
      (!prevProps.isActive && this.props.isActive) || (!prevProps.visible && this.props.visible && this.props.isActive);

    if (shouldRefreshInitialValues) {
      const { record } = this.props;
      this.setState({ initialValues: record && record.get('values') });
    }

    /* в случаях когда смена урла непроизводится, данное условие позволяет удалить неизмененный компонент из очереди сцен */
    if (shouldClose && isActive && !(record && record.get('hasBeenEdit'))) {
      sceneActions.deleteScene(sceneId);
    }
  }

  returnToInitialValues = () => {
    const { catalogId, record } = this.props;
    const recordId = record.get('id');

    if ((record && !record.get('loadError')) || (record && !record.get('hasBeenEdit'))) {
      const originValues = record.get('originValues');
      const returnedValues = originValues && originValues.merge(this.state.initialValues);
      /* возврат значений к исходному состоянию */
      recordActions.updateValues(catalogId, recordId, returnedValues);
      /* вызов лайф сценариев при возвращении карточки к исходному состоянию */
      recordActions.raiseChanges(catalogId, recordId, returnedValues);
    }
  };

  getCatalog(catalogId) {
    const { recordErrorLoading } = this.props;
    if (recordErrorLoading) {
      setTimeout(() => {
        apiActions
          .getCatalog({ catalogId }, undefined, {
            sceneId: this.props.parentSceneId,
          })
          .catch((err) => {
            this.props.onClose && this.props.onClose(this.props.parentSceneId);

            this.props.history.push(getLink(this.props.location, routes.records));
          });
      }, 100);
    }
  }

  onSave = () => {
    const { catalogId, sceneId, recordId, onSave, userId, t } = this.props;

    const checkChangeYourself = recordId === userId;

    recordActions.validateAndSaveRecord(
      {
        catalogId,
        recordId,
      },
      {
        sceneId,
      },
      (result) => onSave && onSave(result),
      (error) => {
        if (typeof error === 'boolean') {
          confirm({
            headerText: t('modals.changeYourselfConfirm.header'),
            text: t('modals.changeYourselfConfirm.text'),
            okText: t('modals.changeYourselfConfirm.okText'),
            cancelText: t('modals.changeYourselfConfirm.cancelText'),
            onOk: () =>
              recordActions.validateAndSaveRecord(
                {
                  catalogId,
                  recordId,
                },
                {
                  sceneId,
                },
                (result) => onSave && onSave(result),
                (error) => error && message.error(error.message || error),
                false,
              ),
          });
        } else {
          error && message.error(error.message || error);
        }
      },
      checkChangeYourself,
    );
  };

  getExtParams(record) {
    const { catalogId, catalog } = this.props;
    return {
      sectionId: catalog.get('sectionId'),
      catalogId,
      recordId: record.get('isNew') ? '$new' : record.get('id'),
    };
  }

  onCreateRecord = () => {
    const { catalogId, sceneId, recordId } = this.props;

    appState.setIn(['records', catalogId, recordId, 'creating'], true);
    appState.setIn(['records', catalogId, recordId, 'createError'], null);
    appState.changed();

    recordActions.validateAndSaveRecord(
      {
        catalogId,
        recordId,
      },
      {
        sceneId,
      },
      (result) => {
        this.props.history.push({
          pathname: `${getLink(this.props.location, routes.records).pathname}/${result.id}`,
          search: this.props.location.search,
        });
      },
      (error) => {
        error && message.error(error.message || error);
        appState.setIn(['records', catalogId, recordId, 'creating'], false);
        appState.changed();
      },
    );
  };

  render() {
    const {
      catalogId,
      catalog,
      sceneId,
      scene,
      record,
      onRefresh,
      onDelete,
      isWebForm,
      isLoading,
      shouldClose,
      isActive,
      dontReturnInitialValues,
      onClose,
      userId,
    } = this.props;

    if (!this.props.match) {
      return null;
    }

    if (!record || !catalog || !catalog.get('fields')) {
      if (this.state.newRecordCreated) {
        return (
          <div className={styles.container}>
            <p className={styles.newRecordCreated}>{this.props.t('record.newRecordCreated')}</p>
          </div>
        );
      }
      return (
        <div className={styles.container}>
          <Loading fullHeight />
        </div>
      );
    }
    const recordHasBeenEdit = record.get('hasBeenEdit') || false;
    let readOnly = true;

    readOnly = !checkAccessOnObject(RESOURCE_TYPES.RECORD, record, PRIVILEGE_CODES.EDIT);
    return (
      <div className={styles.container}>
        <Prompt
          when={recordHasBeenEdit}
          message={(location) => {
            const params = getRouteParams(location);
            if (
              params &&
              params.match.params.recordId !== '$new' &&
              params.match.params.recordId !== this.props.recordId &&
              recordHasBeenEdit
            ) {
              return this.props.sceneId;
            }
            return true;
          }}
          // при создании связанной записи и открытии родительской сцены, нужно закрыть родительскую сцену, при этом не откатывая изменения
          // потому что не происходит преход к самой сцене и не обновляются initialValues
          callback={!dontReturnInitialValues && this.returnToInitialValues}
        />

        {/* to show promp && redirect to all records for rightPanel */}
        {shouldClose && isActive ? <NavRedirect route={routes.records} /> : null}

        <RecordHeader
          catalog={catalog}
          scene={scene}
          hasBeenEdit={recordHasBeenEdit}
          record={record}
          specialPrivileges={record.get('fieldPrivilegeCodes')}
          onRefresh={onRefresh}
          onDelete={onDelete}
          isWebForm={isWebForm}
          isLoading={isLoading}
          onClose={onClose}
          onCreateRecord={this.onCreateRecord}
          onSave={this.onSave}
          onClear={this.props.onClear}
          userId={userId}
          fields={this.state.fields}
          hiddenFields={this.props.hiddenFields}
        />

        <RecordBody sceneId={sceneId} record={record} readOnly={readOnly} catalogId={catalogId} isWebForm={isWebForm} />
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    withRouter(Record),
    ['catalogs', 'scenes', 'modal', 'user', 'records'],
    (props, { catalogs, scenes, modal, user, records }) => {
      const { catalogId, sceneId } = props;
      const hiddenFields = records;
      const scene = scenes.get(sceneId);
      const parentSceneId = scene && scene.get('parentSceneId');
      const shouldClose = scene && scene.get('shouldClose');
      const dontReturnInitialValues = scene && scene.get('notReturnInitialValues');
      const sceneExist = !!scene;

      const isActive =
        // WINDOW scenes are always active
        (sceneExist && scenes.getIn([sceneId, 'container']) == SCENE_CONTAINER.WINDOW) ||
        // only one POPUP scene can be active
        (modal && modal.get('activeScene') === sceneId);

      const userId = user && user.get('id');

      return {
        ...props,
        hiddenFields,
        parentSceneId,
        shouldClose,
        sceneExist,
        dontReturnInitialValues,
        scene,
        catalog: catalogs.get(catalogId),
        visible: modal && modal.get('visible'),
        isActive,
        userId,
      };
    },
  ),
);
