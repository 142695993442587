import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { formatDate, formatTime } from '../../../utils/formatDate';
import { TIME, DATE } from '../../../configs/dateFieldSubTypes';

import styles from './dataTypes.less';

const DateField = (props) => {
  const {
    i18n: { language },
  } = useTranslation();

  moment.locale(language);
  const date = moment(new Date(props.value));

  const type = props.config.get('type') || DATE;

  if (props.value) {
    return (
      <span className={cn(props.containerClassName, styles.simpleField)}>
        {formatDate(date, type)}
        &nbsp;
        {type === TIME || type === DATE ? (
          <span className={styles.extraData}>{type === TIME ? formatTime(date) : date.format('dd').toUpperCase()}</span>
        ) : null}
      </span>
    );
  }
  return null;
};

DateField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  config: PropTypes.object,
};

export default DateField;
