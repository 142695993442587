import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';

import Checkbox from '../UI/Checkbox';

import styles from './dataTypes.less';

const CheckboxesField = (props) => {
  const values = [];
  let fieldValues = _.isObject(props.value) ? props.value.toJS() : props.value;
  if (!_.isArray(fieldValues)) {
    fieldValues = fieldValues ? [fieldValues] : [];
  }

  fieldValues.forEach((val) => {
    const item = props.config.get('items').find((item) => item.get('id') === val);
    if (!item) {
      return;
    }
    values.push({
      name: item.get('name'),
    });
  });
  return (
    <div className={cn(props.containerClassName, styles.listField)}>
      {values.map((value, i) => (
        <Checkbox
          key={i}
          title={value.name}
          className={cn(props.itemClassName, styles.checkboxItem)}
          checked
          readOnly
        />
      ))}
    </div>
  );
};

CheckboxesField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  config: PropTypes.object,
};

export default CheckboxesField;
