import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import userSettingsActions from '../../../../../actions/userSettingsActions';
import color from '../../../../../utils/colorFromString';
import { connect } from '../../../../StateProvider';
import UserMenuHelper from './Helpers';
import CompanyInfoContent from './CompanyInfoContent';
import calculateRemainsRecords from '../../../../../utils/calculateRemainsRecords';
import calculateRemainsLicense from '../../../../../utils/calculateRemainsLicense';
import { checkAccessOnObject } from '../../../../../utils/rights';
import apiActions from '../../../../../actions/apiActions';
import PRIVILEGE_CODES from '../../../../../configs/privilegeCodes';
import RESOURCE_TYPES from '../../../../../configs/resourceTypes';

import styles from './companyInfo.less';
import { SYSTEM_SECTIONS } from '../../../../../configs/systemSections';

const CompanyInfo = (props) => {
  const [feedback, setFeedback] = useState({
    flag: false,
    message: '',
  });

  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  const [feedbackError, setFeedbackError] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [requestProcessed, setRequestProcessed] = useState(false);

  const lastSetColor = useRef(null);

  const usersCompanyCount = props.licenseInfo.get('users');
  const licenseDate = props.licenseInfo.get('expires');
  const recordsLimit = props.licenseInfo.get('records') || 0;

  const companyName = props.companyInfo.get('title');
  const recordsCount = props.companyInfo.get('recordsCount') || 0;

  const remainsRecords = calculateRemainsRecords(recordsLimit, recordsCount);
  const remainsLicense = calculateRemainsLicense(licenseDate);

  useEffect(() => {
    apiActions.getLicense();
  }, []);

  useEffect(() => {
    const { adminSection } = props;
    let isAccessAdmin = false;
    if (adminSection) {
      isAccessAdmin = checkAccessOnObject(RESOURCE_TYPES.SECTION, adminSection, PRIVILEGE_CODES.ADMIN);
    }
    setIsAdmin(isAccessAdmin);
  }, [props.adminSection]);

  const getNumberFromDate = (str) => {
    const result = str.split(' ')[0];

    return Number(result) ? Number(result) : 0;
  };

  const onFinish = (values) => {
    const { email, phone } = values;
    if (!email || !phone) return;
    setRequestProcessed(true);
    const username = props.user && props.user.get('title');
    const company = `${companyName} (${window.location.hostname})`;
    const params = {
      Company: company,
      Name: username,
      Email: email,
      Phone: phone,
      Message: props.t('userMenu.feedback.message', { message: feedback.message }),
      contact_goal: 'callback',
      Pageurl: '/support',
    };

    if (props.feedbackUrl) {
      apiActions
        .feedBack(params, props.feedbackUrl)
        .then(() => {
          setRequestProcessed(false);
          setFeedbackSuccess(true);
        })
        .catch(() => {
          setRequestProcessed(false);
          setFeedbackError(true);
        })
        .finally(() => {
          setRequestProcessed(false);
        });
    }
  };

  const downloadTxtFile = () => {
    // text content
    const texts = ['тест'];

    // file object
    const file = new Blob(texts, { type: 'text/plain' });

    // anchor link
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = `${Date.now()}.txt`;

    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const helpers = [
    {
      id: 0,
      href: '#',
      iconType: 'icon interface-56',
      text: props.t('userMenu.helpers.helpDocumentation'),
      func: () => window.open(props.docsUrl),
    },
    {
      id: 1,
      href: '#',
      iconType: 'icon interface-56',
      text: props.t('userMenu.helpers.helpQuestionToSales'),
      func: () => setFeedback({ flag: true, message: props.t('userMenu.helpers.helpQuestionToSales') }),
    },
  ];

  let { background } = props;
  background = background || color.colorAppBackground();
  const changeHandler = _.debounce(
    (colors) => {
      if (lastSetColor.current === colors.color) {
        return;
      }
      userSettingsActions.setKey('background', colors.color);
      lastSetColor.current = colors.color;
    },
    [100],
  );

  return (
    <div className={styles.containerUserMenuAboutContent}>
      <CompanyInfoContent
        {...props}
        backgroundColor={background}
        remainsLicense={remainsLicense}
        companyName={companyName}
        remainsRecords={remainsRecords}
        recordsLimit={recordsLimit}
        usersCompanyCount={usersCompanyCount}
        isAdmin={isAdmin}
        email={props.user && props.user.get('email')}
        feedback={feedback}
        requestProcessed={requestProcessed}
        setFeedback={setFeedback}
        changeHandler={changeHandler}
        feedbackSuccess={feedbackSuccess}
        feedbackError={feedbackError}
        setFeedbackSuccess={setFeedbackSuccess}
        setFeedbackError={setFeedbackError}
        onFinish={onFinish}
        getNumberFromDate={getNumberFromDate}
        downloadTxtFile={downloadTxtFile}
      />
      <UserMenuHelper helpers={helpers} />
    </div>
  );
};

export default withTranslation()(
  connect(
    CompanyInfo,
    {
      background: ['userSettings', 'background'],
      companyInfo: ['company'],
      licenseInfo: ['license'],
      catalogs: ['catalogs'],
      sections: ['sections'],
      user: ['user'],
      vendor: ['vendor'],
    },
    (props, state) => {
      const adminSection = state.sections.find((section) => section.get('id') === SYSTEM_SECTIONS.SETTINGS);
      const feedbackUrl = state.vendor && state.vendor.get('feedbackUrl');
      const docsUrl = state.vendor && state.vendor.get('docsUrl');
      return {
        adminSection,
        feedbackUrl,
        docsUrl,
        ...props,
        ...state,
      };
    },
  ),
);
