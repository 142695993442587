import React from 'react';
import { useTranslation } from 'react-i18next';
import ObjectSelector from './ObjectSelector';

const UserSelector = (props) => {
  const { t } = useTranslation();
  return (
    <ObjectSelector
      remoteGroup={props.field.get('elementsRemoteGroup')}
      placeholder={props.placeholder || t('record.fields.user.addUser')}
      {...props}
    />
  );
};

export default UserSelector;
