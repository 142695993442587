import React from 'react';

import { withTranslation } from 'react-i18next';
import Header from '../../../Record/Header';
import { BATCH_RECORDS_STATUS } from '../../../../configs/recordsBatchStatus';

const RecordsBatchDeleteHeader = (props) => {
  const isDeleting = props.scene && props.scene.getIn(['data', 'batchStatus']) === BATCH_RECORDS_STATUS.DELETING;
  const onClose = () => {
    const { scene } = props;
    const sceneId = scene.get('sceneId');
    if (!isDeleting) {
      props.onClose && props.onClose(sceneId);
    }
  };
  const recordsCount = props.scene.get('recordsCount') || 0;
  const headerText = `${props.t('batchDeleteRecords.title')} ${props.t('record.groupRecords.count', { count: +recordsCount })}`;
  return (
    <Header
      batchDelete
      catalog={props.catalog}
      scene={props.scene}
      headerText={headerText}
      onPauseUpdate={props.onPauseUpdate}
      onResumeUpdate={props.onResumeUpdate}
      onClose={onClose}
      isLoading={props.isLoading}
      isDeleting={isDeleting}
    />
  );
};

export default withTranslation()(RecordsBatchDeleteHeader);
