import { Image } from 'antd';
import React from 'react';
import cn from 'classnames';

import ButtonClose from '../../../../../ButtonClose';
import i18n from '../../../../../../../../configs/i18n';

import styles from './image.less';

export const antImageViewer = 'antImageViewer';

const AntImage = ({
  files,
  readOnly,
  containerClassName,
  itemWrapperClassName,
  itemClassName,
  width,
  height,
  calcSizes,
  onRemove,
}) => (
  <div className={cn(containerClassName)}>
    {files.length &&
      files.map((file, i) => {
        const size = calcSizes
          ? calcSizes(files, file)
          : {
              width,
              height,
            };
        const sizeMax = Math.max(size.width || 0, size.height || 0);
        let { url } = file;
        if (sizeMax <= 128 && file?.metadata?.thumbnail) {
          url = file.metadata.thumbnail;
        } else if (sizeMax <= 700 && file?.metadata?.preview) {
          url = file.metadata.preview;
        }

        return (
          <div key={i} className={cn(itemWrapperClassName)}>
            <Image
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={size}
              preview={{
                src: file.url, // put here original image size
              }}
              className={cn(styles.image, itemClassName)}
              src={url}
            />
            {!readOnly && (
              <ButtonClose title={i18n.t('record.fields.file.remove')} onClick={() => onRemove(file)} small />
            )}
          </div>
        );
      })}
  </div>
);

export default AntImage;
