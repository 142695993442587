import React from 'react';

import Icon from '../../../../../../common/UI/Icon';

import styles from './feedBack.less';

const FeedbackSuccess = ({ setFeedbackSuccess, t, setFeedback }) => (
  <div className={styles.containerLicenseAbout}>
    <div className={styles.feedbackSuccessContainerItems}>
      <h4 style={{ margin: 0 }} className={styles.feedbackTitle}>
        {t('userMenu.feedback.feedbackSuccess')}
      </h4>
      <Icon
        onClick={() => {
          setFeedbackSuccess(false);
          setFeedback({
            flag: false,
            message: '',
          });
        }}
        type="icon interface-74"
        className={styles.feedbackSuccessIcon}
      />
    </div>
  </div>
);

export default FeedbackSuccess;
