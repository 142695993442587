/* eslint-disable default-param-last */
import _ from 'lodash';

import AppState from '../appState';
import DEFAULTS from '../configs/records';
import filtersUtils from './filters';

export default {
  _prepareGetRecordsArgs(
    catalogId,
    sceneId,
    request = {},
    viewMode = 'table',
    filters = [],
    sortParams,
    actionParams = {},
    abortCurrentGET,
  ) {
    if (!sceneId) {
      throw new Error('Undefined sceneId for request records!');
    }

    // sorting
    sortParams = sortParams || this.getSortParams(catalogId, viewMode);
    request = _.cloneDeep(request);
    request = Object.assign(request, sortParams);

    // search text
    request.searchText = AppState.getSearchText(sceneId);

    // filter by new messages
    const filterNewMessages = AppState.getFilterNewMessagess(sceneId);
    if (filterNewMessages) {
      request.filterNewMessages = filterNewMessages;
    }

    // limit of records
    request.limit = request.limit || DEFAULTS.RECORDS_LIMIT[viewMode];

    const { viewId = 0 } = request;
    const filtersChanged = AppState.getIn(['scenes', sceneId, 'views', viewId, 'filtersChanged']);

    /* поправить */
    const filtersFromView = this.getFilterParams(sceneId, viewId) || []; // фильтры вида
    const filtersFromScene = this.getAdditionalFilterParams(sceneId) || []; // фильтры ("data", "filters")

    const requestFilters = this.prepareRequestFilters(request.filters, catalogId);
    let allFilters = requestFilters || [];

    if (filtersFromScene) {
      allFilters = _.concat(allFilters, filtersFromScene);
    }
    if (filters) {
      allFilters = _.concat(allFilters, filters);
    }
    if (filtersChanged) {
      allFilters = _.concat(allFilters, filtersFromView);
    }

    if (allFilters) {
      request.filters = allFilters;
    }

    if (viewId === '0' || viewId === '$new' || filtersChanged) {
      delete request.viewId; // удаление вида (потому что сервер не знает о 0 и "$new" видах)
    }

    return [{ catalogId }, request, { sceneId, ...actionParams }, abortCurrentGET];
  },

  getSortParams(catalogId, viewMode = 'table') {
    const sortingRecordSetting = AppState.getIn([
      'userSettings',
      'catalogs',
      catalogId,
      'viewMode',
      viewMode,
      'sortingRecords',
    ]);
    let sortField = sortingRecordSetting && sortingRecordSetting.get('sortField');
    let sortType = sortingRecordSetting && sortingRecordSetting.get('sortType');

    // check if sort field exists in catalog
    if (sortField && sortField != 'id') {
      const catalogFields = AppState.getIn(['catalogs', catalogId, 'fields']);
      if (!catalogFields.find((field) => field.get('id') == sortField)) {
        sortField = undefined;
      }
    }

    // set dafault sorting
    if (!sortField) {
      sortField = 'id';
      sortType = -1;
    }

    return { sortField, sortType };
  },

  prepareRequestFilters(filters, catalogId) {
    const catalogFields = AppState.getIn(['catalogs', catalogId, 'fields']);
    return filters && filtersUtils.getFiltersForRequest(filters, catalogFields);
  },

  getFilterParams(sceneId, viewId) {
    const filters = AppState.getFiltersForRequest({ sceneId, viewId });
    return filters;
  },

  getAdditionalFilterParams(sceneId) {
    const filters = AppState.getFiltersForRequest({ sceneId });
    return filters;
  },
};
