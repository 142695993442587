import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './access.less';

const EmptyRules = (props) => {
  const count = Math.max(props.count, 1);
  const emptyList = [];

  for (let i = 0; i < count; i++) {
    emptyList.push(<div className={styles.emptyRow} key={i} />);
  }

  return <div>{emptyList}</div>;
};

EmptyRules.propTypes = {
  count: PropTypes.number.isRequired,
};

export default EmptyRules;
