import React, { useState } from 'react';
import _ from 'lodash';
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';

import ButtonTransparent from '../../../common/UI/ButtonTransparent';
import Icon from '../../../common/UI/Icon';
import Logo from '../Logo';
import Overlay from './Overlay';

import styles from './fullMenu.less';

const FullMenu = (props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const hideMenu = () => {
    setVisible(false);
  };

  const openMenu = () => {
    setVisible(true);
  };

  return (
    <div className={styles.wrapper}>
      <ButtonTransparent className={styles.mainMenuButton} onClick={openMenu} light title={t('menuPopover.menu')}>
        <Icon type="icon content-43" className={styles.mainMenuIcon} />
        <Logo />
      </ButtonTransparent>

      <Drawer
        placement="left"
        width={300}
        closable={false}
        bodyStyle={{ padding: 0 }}
        onClose={hideMenu}
        open={visible}
      >
        <Overlay visible={visible} hideMenu={hideMenu} />
      </Drawer>
    </div>
  );
};

export default FullMenu;
