import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';
import RecordDropdown from './RecordDropdown';

import styles from './controls.less';

const User = (props) => {
  const { t } = useTranslation();
  return (
    <RecordDropdown
      {...props}
      wrapperClassName={cn(styles.user, props.className)}
      placeholder={t('record.fields.user.addUser')}
      remoteGroup={props.controlConfig.get('elementsRemoteGroup')}
      blockForceUpdateForEmpty
      loadAvailableItems={props.controlConfig.get('loadAvailableItems')}
      clearAvailableItems={props.controlConfig.get('clearAvailableItems')}
    />
  );
};

User.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
};

export default User;
