import React from 'react';
import PropTypes from 'prop-types';

import ExtendedLinkedRecord from '../ExtendedLinkedRecord';
import ListItem from '../LinkedRecordsInListMode/ListItem';
import { getParams } from '../utils';

const LinkedRecordsInCardMode = (mainProps) => {
  const { RecordSelect, sceneId, ...props } = mainProps;

  return (
    <>
      {props.items.map((item) => {
        if (!item) {
          return;
        }
        const itemRemovableProperty = item.getIn(['item', 'removable']);
        const itemData = item.get('item');
        const canRestore = item.get('canRestore');
        const removed = itemData && itemData.get('isRemoved');

        const params = getParams(item, props.controlConfig, props.remoteGroup);
        const extendedFields = params && params.catalogId && props.extendedFields.get(params.catalogId);

        const removable = itemRemovableProperty === false ? itemRemovableProperty : !props.readOnly && !props.apiOnly;

        if (extendedFields && !removed && !canRestore) {
          return (
            <ExtendedLinkedRecord
              sceneId={sceneId}
              key={item.get('key')}
              item={item}
              extFields={extendedFields}
              params={params}
              removed={removed}
              readOnly={props.readOnly}
              removable={removable}
              onRemoveItem={() => props.onRemoveItem && props.onRemoveItem(item)}
            />
          );
        }
        return (
          <ListItem
            sceneId={sceneId}
            key={item.get('key')}
            item={item}
            params={params}
            remoteGroup={props.remoteGroup}
            editable={!props.readOnly}
            onRemoveItem={() => props.onRemoveItem && props.onRemoveItem(item)}
            onRestoreItem={() => props.onRestoreItem && props.onRestoreItem(item)}
          />
        );
      })}
      {RecordSelect ? <RecordSelect.type additionalItems={props.additionalItems} {...RecordSelect.props} /> : null}
    </>
  );
};

LinkedRecordsInCardMode.propTypes = {
  items: PropTypes.object,
  editable: PropTypes.bool,
  readOnly: PropTypes.bool,
  params: PropTypes.object,
  onClickItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

export default LinkedRecordsInCardMode;
