import React from 'react';
import { withTranslation } from 'react-i18next';
import { Route } from 'react-router';
import HashMemoryRouter from '../common/router/HashMemoryRouter';
import ComponentRecord from './ComponentRecord';
import getConfirmation from '../common/Modal/getConfirmation';
import { connect } from '../StateProvider';

const RecordModal = ({ ...props }) => {
  const { params, catalogs, route, t } = props;
  if (!params.sectionId) {
    params.sectionId = catalogs.getIn([params.catalogId, 'sectionId']);
  }
  const { path } = route;
  return (
    <HashMemoryRouter
      initialEntries={[path]}
      initialIndex={1}
      getUserConfirmation={(message, callback) => getConfirmation(message, callback, null, undefined, t)}
    >
      <Route
        path={path}
        children={(routeProps) => (
          <ComponentRecord catalogId={params.catalogId} recordId={params.recordId} {...props} {...routeProps} />
        )}
      />
    </HashMemoryRouter>
  );
};

export default connect(withTranslation()(RecordModal), ['catalogs']);
