import _ from 'lodash';
import React from 'react';

import { TipList } from './TipList';

import styles from '../styles/helper.less';

export const ItemGroup = ({ title, description, items, switchToTutorial }) => (
  <div>
    <h2 className={styles.headerTitle}>{title}</h2>
    {description && <div className={styles.mainDescription}>{description}</div>}
    {items &&
      items.map((item) => {
        if (!_.isEmpty(item.items)) return <TipList switchToTutorial={switchToTutorial} key={item.title} {...item} />;
      })}
  </div>
);
