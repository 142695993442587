import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Input, Alert, Col, Form } from 'antd';

import cn from 'classnames';
import _ from 'lodash';
import ButtonClose from '../UI/ButtonClose';
import LoadingSpin from '../LoadingSpin';
import styles from './modal.less';

const PromptModal = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [errorText, setErrorText] = useState('');

  const { okText, onOk, onCancel, defaultValue, cancelText, headerText, text, promptHint, loading, validateFunc, t } =
    props;

  useEffect(() => {
    setInputValue(defaultValue || '');
  }, []);

  const onSave = (e) => {
    e.preventDefault();
    const validateError = validateFunc && t ? validateFunc(inputValue, t, defaultValue || null) : '';
    if (validateError) {
      setErrorText(validateError);
      return;
    }
    onOk(inputValue);
  };

  // disabled не нужен, так как мы можем передать пустое поле и это ок
  // В таком случае мы просто удаляем id из recordIds
  // const disabled = !inputValue.length;
  const modalFooter = [
    <Button
      key="submit"
      // disabled={disabled}
      type="primary"
      onClick={onSave}
    >
      {okText}
    </Button>,
    <Button key="back" type="default" onClick={onCancel}>
      {cancelText}
    </Button>,
  ];

  if (loading) {
    modalFooter.push(<LoadingSpin className={cn(styles.footerLoader)} />);
  }

  return (
    <Modal open maskClosable={false} closable={false} className={cn(styles.promptModalContainer)} footer={modalFooter}>
      <Row type="flex" justify="space-between" align="middle" className={styles.header}>
        <h1>{headerText}</h1>
        <ButtonClose large className={styles.close} shiftRight onClick={onCancel} />
      </Row>
      <Col className={styles.content}>
        <p>{text}</p>
        <Form onSubmit={onSave} className={styles.form}>
          <Input
            type="text"
            onChange={(e) => setInputValue(_.trim(e.target.value))}
            placeholder={props.placeHolder}
            value={inputValue}
            className={cn(styles.promptModalInput)}
          />
          {promptHint && (
            <div className={styles.promptHintContainer}>
              <p className={styles.promptHintText}>{promptHint}</p>
            </div>
          )}
        </Form>
      </Col>
      {errorText && (
        <div className={styles.message}>
          <Alert banner closable={false} message={errorText} type="warning" />
        </div>
      )}
    </Modal>
  );
};

export default PromptModal;
