import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../../../UI/Icon';
import { VideoViewer } from '../../../Modal/VideoViewer';

import styles from '../styles/helper.less';

const HelperDetail = ({ toggleVisibility, modalVisible, tutorial, switchToTutorial }) => {
  const { t } = useTranslation();
  const content = tutorial.items ? (
    <div style={{ padding: 20 }}>
      <h2 className={styles.headerTitle}>{tutorial.title}</h2>
      <ul>
        {tutorial &&
          tutorial.items.map((item) => (
            <li className={styles.backlink} key={item.title} onClick={() => switchToTutorial(item.code)}>
              {item.title}
            </li>
          ))}
      </ul>
    </div>
  ) : (
    <div className={styles.helperDetailBody} style={{ width: '100%' }}>
      {tutorial.url && (
        <div className={styles.smallVideoContainer}>
          <video src={tutorial.url} width="100%" className={styles.detailVideo} />
          <div className={styles.videoIcon} onClick={toggleVisibility}>
            <Icon type="icon arrows-triangle-big-circle-4-01" />
          </div>
        </div>
      )}

      <div className={styles.helperDetailContent}>
        <h3 className={styles.detailTitle}>{tutorial.title}</h3>
        <div>{tutorial.description}</div>

        {tutorial.additional && !_.isEmpty(tutorial.additional) && (
          <div className={styles.additional}>
            <p className={styles.additionalTitle}>{t('helper.morePosts')}</p>
            <ul className={styles.additionalList}>
              {tutorial.additional.map((addItem) => (
                <li>
                  <Icon type="icon interface-76" /> <a href={addItem.url}>{addItem.title}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <VideoViewer onCancel={() => toggleVisibility()} visible={modalVisible} url={tutorial.url} />
    </div>
  );
  return content;
};

HelperDetail.propTypes = {
  toggleVisibility: PropTypes.func,
  modalVisible: PropTypes.bool,
  tutorial: PropTypes.object,
};

export { HelperDetail };
