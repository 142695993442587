import React, { PureComponent } from 'react';
import FieldConfig from '../common/FieldConfig';

import styles from './history.less';
import historyActions from '../../actions/historyActions';

class FieldFilter extends PureComponent {
  state = {};

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ fields: this.props.getFieldsToRender() });
  }

  componentDidUpdate(prevProps, prevState) {
    const { catalogId, recordId } = this.props;
    const prevFields = prevState.fields;
    let { fields } = this.state;

    if ((prevFields && prevFields.size) !== (fields && fields.size)) {
      fields = fields.map((field) => field.get('id')).toJS();
      historyActions.setFilter({ ...this.props.filter, fields }, { catalogId, recordId });
    }
  }

  render() {
    const { catalogId, fields } = this.props;
    return (
      <FieldConfig
        catalogId={catalogId}
        fields={fields}
        viewMode={this.props.viewMode}
        sortable={false}
        className={styles.buttonFieldConfig}
      />
    );
  }
}

export default FieldFilter;
