import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import getLink from './getLink';

const NavRedirect = ({ route, params }) => (
  <Route
    children={({ location }) => {
      const href = getLink(location, route, params);
      return <Redirect to={href} />;
    }}
  />
);

export default NavRedirect;
