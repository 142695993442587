import React, { PureComponent } from 'react';
import Immutable, { fromJS } from 'immutable';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import FIELD_TYPES from '../../../configs/fieldTypes';
import userSettingsActions from '../../../actions/userSettingsActions';
import dndContext from '../../../services/dndContext';
import { connect } from '../../StateProvider';
import FieldConfigItem from './FieldConfigItem';
import Icon from '../UI/Icon';
import ButtonTransparent from '../UI/ButtonTransparent';

import styles from './fieldConfig.less';

class FieldConfigMenu extends PureComponent {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    catalogId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      fields: Immutable.Map(),
      visibleFields: {},
      fieldsOrder: [],
    };
  }

  /* SET */

  componentDidMount() {
    this.setFields();
    this.setFieldsVisible();
  }

  componentDidUpdate(prevProps, prevState) {
    // visiblity chaged
    if (this.props.fields !== prevProps.fields || this.props.visibleFields !== prevProps.visibleFields) {
      this.setFieldsVisible();
    }

    // order chaged
    if (this.props.fields !== prevProps.fields || this.props.fieldsOrder !== prevProps.fieldsOrder) {
      this.setFields();
    }
  }

  setFields = () => {
    let { fields, fieldsOrder } = this.props;

    // fields order
    fieldsOrder = fieldsOrder ? fieldsOrder.toJS() : [];
    fieldsOrder = _.uniq(fieldsOrder);
    const fieldIds = fields && fields.map((col) => col.get('id')).toArray();
    const notSetOrderOnField = _.difference(fieldIds, fieldsOrder);
    fieldsOrder = (fieldsOrder || []).filter((id) => fieldIds.indexOf(id) !== -1).concat(notSetOrderOnField);

    // fields
    fields = fields
      .filter((col) => col.get('type') !== FIELD_TYPES.GROUP && col.get('type') !== FIELD_TYPES.BUTTON)
      .filter((col) => col.get('hidden') !== true)
      .map((col) => {
        const index = fieldsOrder.indexOf(col.get('id'));
        return col.set('_order', index === -1 ? Number.MAX_SAFE_INTEGER : index);
      })
      .sort((c1, c2) => c1.get('_order') - c2.get('_order'));

    // visible fields

    this.setState({ fields, fieldsOrder });
  };

  setFieldsVisible = () => {
    // visible fields
    const { fields } = this.props;
    const { visibleFields } = this.props;
    const defaultVisible = this.props.defaultVisible === undefined ? true : this.props.defaultVisible;

    const newVisibleFields = {};
    fields
      .filter((col) => col.get('type') !== FIELD_TYPES.GROUP && col.get('type') !== FIELD_TYPES.BUTTON)
      .filter((col) => col.get('hidden') !== true)
      .forEach((field) => {
        const fieldId = field.get('id');
        let visible = visibleFields.getIn([fieldId, 'visible', 'visible']);
        visible = visible === undefined ? defaultVisible : visible;
        newVisibleFields[fieldId] = visible;
      });

    this.setState({ visibleFields: newVisibleFields });
  };

  /* CHECK */
  hasVisibleFields = () => {
    const { visibleFields } = this.state;
    return !!_.findKey(visibleFields, Boolean);
  };

  hasNotVisibleFields = () => {
    const { visibleFields } = this.state;
    return !!_.findKey(visibleFields, (f) => f !== true);
  };

  /* EVENTS */

  /* Change Visible */
  onChangeVisibility(fieldId, visible) {
    // update local state
    const { visibleFields } = this.state;
    visibleFields[fieldId] = visible;
    this.setState({ visibleFields });

    // update remote state
    userSettingsActions.setFieldVisibility({
      catalogId: this.props.catalogId,
      viewMode: this.props.viewMode,
      fieldId,
      visible,
    });
  }

  showAllFields = () => {
    const defaultVisible = this.props.defaultVisible === undefined ? true : this.props.defaultVisible;
    if (defaultVisible) {
      this.unsetAllFields();
    } else {
      this.setAllFields(true);
    }
  };

  hideAllFields = () => {
    const defaultVisible = this.props.defaultVisible === undefined ? true : this.props.defaultVisible;
    if (defaultVisible) {
      this.setAllFields(false);
    } else {
      this.unsetAllFields();
    }
  };

  setAllFields(value) {
    const { catalogId, viewMode, fields } = this.props;
    let visibleFields = this.props.visibleFields || Immutable.Map();
    const localVisibleFields = this.state.visibleFields;
    const defaultVisible = this.props.defaultVisible === undefined ? true : this.props.defaultVisible;
    let updateSettings = Immutable.Map();

    const visibleValue = fromJS({
      visible: {
        visible: value,
      },
    });

    fields.forEach((field) => {
      if (field.get('type') !== 'group') {
        const fieldId = field.get('id');
        const currentValue = visibleFields.getIn([fieldId, 'visible', 'visible']);

        // prepare update in remote
        if (currentValue !== value) {
          updateSettings = updateSettings.set(fieldId, visibleValue);
        }

        // prepare update in state
        if (!visibleFields.getIn([fieldId])) {
          visibleFields = visibleFields.set(fieldId, Immutable.Map());
        }
        visibleFields = visibleFields.setIn([fieldId, 'visible'], fromJS({ visible: value }));

        // prepare update in local state
        localVisibleFields[fieldId] = value === undefined ? defaultVisible : value;
      }
    });

    // update in local
    this.setState({ visibleFields: localVisibleFields });

    // update in store
    // нельзя менять на сервере: ато запишеться обьект с ключами, и он не перезатрет отдельные ключи на сервере
    userSettingsActions.setUserSettingsToAppState(
      ['catalogs', this.props.catalogId, 'viewMode', this.props.viewMode, 'fields'],
      visibleFields,
    );
    // update in remote
    updateSettings.forEach((s, fieldId) => {
      userSettingsActions.setFieldVisibility({
        catalogId,
        viewMode,
        fieldId,
        visible: value,
      });
    });
  }

  unsetAllFields() {
    return this.setAllFields(undefined);
  }

  /* Change order  */
  moveItem = (fieldId, afterFieldId) => {
    if (this.field !== fieldId || this.afterFieldId !== afterFieldId) {
      this.field = fieldId;
      this.afterFieldId = afterFieldId;

      const fieldIndex = this.state.fieldsOrder.indexOf(fieldId);
      const newFieldIndex = this.state.fieldsOrder.indexOf(afterFieldId);

      const newOrder = this.state.fieldsOrder.slice();
      newOrder.splice(fieldIndex, 1);
      newOrder.splice(newFieldIndex, 0, fieldId);

      this.setState({
        fieldsOrder: newOrder,
      });
    }
  };

  onDragEnd = () => {
    userSettingsActions.setFieldsOrder({
      catalogId: this.props.catalogId,
      viewMode: this.props.viewMode,
      fieldsOrder: this.state.fieldsOrder,
    });
  };

  render() {
    const { fields, visibleFields } = this.state;
    const { viewMode, sortable, t } = this.props;

    return (
      <ul className={cn('ant-dropdown-menu ant-dropdown-menu-vertical', styles.menu)}>
        {fields.map((col) => {
          const colId = col.get('id');
          // get setting for current FieldConfigItem, from UserSetting store.
          return (
            <FieldConfigItem
              viewMode={viewMode}
              key={colId}
              field={col}
              visible={visibleFields[colId]}
              sortable={sortable}
              onChange={(e) => this.onChangeVisibility(colId, e.target.checked)}
              onDragEnd={this.onDragEnd}
              moveItem={this.moveItem}
            />
          );
        })}
        {this.hasVisibleFields() && (
          <li className={cn('ant-dropdown-menu-item', styles.showItem)}>
            <ButtonTransparent
              className={styles.showBtn}
              title={t('record.filter.removeTitle')}
              onClick={this.hideAllFields}
            >
              <Icon type="icon edition-39" />
              <span>{t('record.filter.hide')}</span>
            </ButtonTransparent>
          </li>
        )}
        {this.hasNotVisibleFields() && (
          <li className={cn('ant-dropdown-menu-item', styles.showItem)}>
            <ButtonTransparent
              className={styles.showBtn}
              title={t('record.filter.removeTitle')}
              onClick={this.showAllFields}
            >
              <Icon type="icon edition-24" />
              <span>{t('record.filter.show')}</span>
            </ButtonTransparent>
          </li>
        )}
      </ul>
    );
  }
}

export default withTranslation()(
  dndContext(
    connect(
      FieldConfigMenu,
      {
        userSettings: ['userSettings', 'catalogs'],
      },
      (props, { userSettings }) => {
        const { catalogId } = props;
        const settings = userSettings.getIn([catalogId, 'viewMode', props.viewMode]) || Immutable.Map();
        return {
          ...props,
          fieldsOrder: settings.getIn(['fieldsOrder', 'fieldsOrder']) || Immutable.List(),
          visibleFields: settings.getIn(['fields']) || Immutable.Map(),
        };
      },
    ),
  ),
);
