import Immutable from 'immutable';
import _ from 'lodash';
import { message } from 'antd';

import i18n from '../../configs/i18n';
import SCENE_TYPE from '../../configs/sceneTypes';

const recordMixinRecieving = {
  /*
    Record
  */

  getRecord({ catalogId, recordId }, query, actionParams) {
    const recordKey = ['records', catalogId, recordId];

    // create empty record
    if (!this.getIn(recordKey)) {
      const newRecord = { id: recordId };

      // copy record data from parent scene (catalog.records) for fast preview data
      const { sceneId } = actionParams;
      const scene = sceneId && this.getIn(['scenes', sceneId]);
      const parentSceneId = scene && scene.get('parentSceneId');
      const parentScene = parentSceneId && this.getIn(['scenes', parentSceneId]);
      if (parentScene && parentScene.get('type') == SCENE_TYPE.CATALOG) {
        const records = parentScene.get('records');
        const copyRecord = records && records.find((r) => r.get('id') == recordId);
        if (copyRecord) {
          newRecord.title = copyRecord.get('title');
          newRecord.values = copyRecord.get('values').toJS();
        }
      }

      this.setRecord({ catalogId, recordId }, newRecord);
    }

    // set loading state
    this.setIn([...recordKey, 'loading'], true);
    this.setIn([...recordKey, 'loadError'], null);

    this.changed();
  },

  getRecordCompleted(body, params, data, query, res, actionParams) {
    const recordKey = ['records', params.catalogId, params.recordId];

    this.setRecord(params, body, true);

    // update record state
    this.setIn([...recordKey, 'history', 'count'], body.historyCount);
    this.setIn([...recordKey, 'saving'], false);
    this.setIn([...recordKey, '_loadTime'], Date.now());
    this.setIn([...recordKey, 'loading'], false);
    this.setIn([...recordKey, 'loadError'], null);

    this.changed();
  },

  getRecordFailed(err, params) {
    const { alert } = require('../../components/common/Modal');

    const errText = _.isObject(err) && err.text ? err.text : '';
    this.setIn(['records', params.catalogId, params.recordId, 'loading'], false);
    this.setIn(['records', params.catalogId, params.recordId, 'loadError'], errText || true);

    const isAccessDenied = err && err.status == 403;
    const notFound = err && err.status == 403;
    const serverError = err ? err.status && err.status[0] === 5 : false;

    if (isAccessDenied) {
      /* можно разные текста использовать */
      message.error(errText || i18n.t('modals.denied.record.read.text'));
    } else if (notFound) {
      message.error(errText || i18n.t('modals.notFound.record.text'));
    } else if (serverError) {
      message.error(errText || i18n.t('modals.loadRecordError.text'));
    } else if (!params.notShowAlertWhenCreatingRecordWithRelatedRecordFiltersWhenThereAreNoRighhts) {
      alert({
        headerText: (err && err.title) || i18n.t('modals.denied.record.read.headerText'),
        text: errText || i18n.t('modals.denied.record.read.text'),
        okText: i18n.t('modals.denied.okText'),
      });
    }

    this.changed();
  },

  /*
    Relations
  */

  getRelations(params, query) {
    const recordKey = ['records', params.catalogId, params.recordId];

    if (!this.getIn(recordKey)) {
      const { catalogId, recordId } = params;
      this.setRecord({ catalogId, recordId }, { id: recordId });
    }

    const linkedData = this.getIn([...recordKey, 'linkedData']);

    // Here we check if the query is, it means that user wants to see more records.
    // Then we try to find index of current linkedDataItem, and set the loading status to true
    if (query && query.catalogId && linkedData) {
      // finding index of item by comparison "fileId" and "catalogId"
      const index = linkedData.findIndex(
        (item) => item.get('fieldId') === query.fieldId && item.get('id') === query.catalogId,
      );

      // Push the status in appStore
      this.setIn([...recordKey, 'linkedData', index, 'loading'], true);
    } else {
      this.setIn([...recordKey, 'linkedDataLoading'], true);
    }
    this.changed();
  },

  getRelationsCompleted(data, params, postData, query) {
    const recordKey = ['records', params.catalogId, params.recordId];

    let record = this.getIn(recordKey);
    const linkedData = this.getIn([...recordKey, 'linkedData']);

    // Here we check if the query is, it means that user wants to see more records.
    // Then we try to find index of current linkedDataItem, and push new data items and set the loading status to false
    if (query && query.catalogId && linkedData) {
      // finding index of item by comparison "fileId" and "catalogId"
      const index = linkedData.findIndex(
        (item) => item.get('fieldId') === query.fieldId && item.get('id') === query.catalogId,
      );

      // getting the linkedRecords from appStore
      let linkedRecords = linkedData.getIn([index, 'records']);

      // checking data from server API
      if (data[0]) {
        // getting records from data
        let newLinkedRecords = data[0].records;

        // set data to appState, cause of extra parametrs also changed (check "recordsTotal")
        this.setIn([...recordKey, 'linkedData', index], Immutable.fromJS(data[0]));

        // for case when someone removed data and offset = 0
        if (query.offset) {
          // checking for data from server API and converts it to Immutable
          newLinkedRecords = Immutable.fromJS(newLinkedRecords || []);

          // merging curent data from appStore with data from server
          linkedRecords = linkedRecords.concat(newLinkedRecords);

          this.setIn([...recordKey, 'linkedData', index, 'records'], linkedRecords);
        }
      } else {
        // removing data, when someone changing it from the other pc
        const linkedData = this.getIn([...recordKey, 'linkedData', index]);
        this.setIn([...recordKey, 'linkedData', index], linkedData.deleteAll()); // probably deleteIn
      }

      // changing status and overwrite the records
      this.setIn([...recordKey, 'linkedData', index, 'loading'], false);
    } else if (record) {
      record = record.set('linkedData', Immutable.fromJS(data || []));
      record = record.set('_linkedDataLoadTime', Date.now());
      record = record.set('linkedDataLoading', false);

      this.setIn(['records', params.catalogId, params.recordId], record);
    }
    this.changed();
  },

  getRelationsFailed(data, params, postData, query) {
    const recordKey = ['records', params.catalogId, params.recordId];

    const linkedData = this.getIn([...recordKey, 'linkedData']);
    if (query && query.catalogId && linkedData) {
      // finding index of item by comparison "fileId" and "catalogId"
      const index = linkedData.findIndex(
        (item) => item.get('fieldId') === query.fieldId && item.get('id') === query.catalogId,
      );

      // changing status cause of failed request
      this.setIn([...recordKey, 'linkedData', index, 'loading'], false);
    } else {
      this.setIn([...recordKey, 'linkedDataLoading'], false);
    }
    this.changed();
  },

  /*
    Records collections
  */

  getRecords(params, query, actionParams) {
    if (!(actionParams && actionParams.sceneId)) {
      return;
    }
    let scene = this.getIn(['scenes', actionParams.sceneId]);

    // catalog not set after location.reload()
    if (scene) {
      if (query.offset === 0) {
        scene = scene.set('records', Immutable.Map({}));
        scene = scene.set('recordsCount', undefined);
      }

      scene = scene.merge(
        Immutable.fromJS({
          loading: true,
          loadingError: null,
          query,
        }),
      );

      this.setIn(['scenes', actionParams.sceneId], scene);
      this.setShouldReload(actionParams.sceneId, false);
      this.changed();
    }
  },

  getRecordsFailed(err, params, data, query, actionParams) {
    const errText = _.isObject(err) && err.text ? err.text : '';

    const isAccessDenied = err && err.status == 403;
    const notFound = err && err.status == 403;
    const serverError = err && err.status[0] == 5;

    if (isAccessDenied) {
      /* можно разные текста использовать */
      message.error(errText || i18n.t('modals.denied.record.read.text'));
    } else if (notFound) {
      message.error(errText || i18n.t('modals.denied.record.read.text'));
    } else if (serverError) {
      message.error(errText || i18n.t('modals.loadRecordError.text'));
      // eslint-disable-next-line no-dupe-else-if
    } else if (isAccessDenied) {
      const { alert } = require('../../components/common/Modal');
      alert({
        headerText: (err && err.title) || i18n.t('modals.denied.record.read.headerText'),
        text: errText || i18n.t('modals.denied.record.read.text'),
        okText: i18n.t('modals.denied.okText'),
      });
    }
  },
};

export default recordMixinRecieving;
