import React from 'react';
import { Modal, Button, Row, Col } from 'antd';

import ButtonClose from '../UI/ButtonClose';

import styles from './modal.less';

const AlertWrapper = (props) => (
  <Modal
    open
    maskClosable={false}
    closable={false}
    footer={[
      <Button key="submit" type="default" onClick={props.onOk}>
        {props.okText}
      </Button>,
    ]}
  >
    <Row type="flex" justify="space-between" align="middle" className={styles.header}>
      <h1>{props.headerText}</h1>
      <ButtonClose className={styles.close} shiftRight onClick={props.onCancel} />
    </Row>
    <Col className={styles.content}>{props.children}</Col>
  </Modal>
);

export default AlertWrapper;
