import _ from 'lodash';
import React from 'react';
import { Route } from 'react-router-dom';

import getLink from './getLink';

const NavLink = ({ render, route, params, ...props }) => (
  <Route>
    {({ location }) => {
      const link = getLink(location, route, params);
      return (
        <Route path={link.pathname}>
          {({ match, history }) => {
            const isActive = !!match;
            const _link = link.pathname;

            return render({ ...props, history, isActive, link: _link });
          }}
        </Route>
      );
    }}
  </Route>
);

export default NavLink;
