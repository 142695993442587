import React from 'react';
import { Button } from 'antd';
import cn from 'classnames';
import styles from './buttonTransparent.less';

const ButtonTransparent = (props) => (
  <Button
    onClick={props.onClick}
    onMouseDown={props.onMouseDown}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    disabled={props.disabled}
    className={cn(styles.btn, props.light ? styles.light : styles.dark, props.className)}
    title={props.title}
    data-badge={props['data-badge']}
  >
    {props.children}
  </Button>
);

export default ButtonTransparent;
