import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { matchPath } from 'react-router';

import NavRoute from '../../common/router/Route';
import routes from '../../../routes';

import AppHeader from '../AppHeader';
import Section from './Section';
import Loading from '../../common/Loading';

import styles from './appBody.less';

const AuthPage = lazy(() => import('../../authForms'));

const AppBody = () => (
  <div className={styles.container}>
    <Route
      render={(props) => {
        const { location } = props;

        const matchAuth = matchPath(location.pathname, {
          path: routes.auth.path,
          exact: false,
          strict: false,
        });

        if (matchAuth) {
          return (
            <Suspense fallback={<Loading />}>
              <AuthPage {...props} />;
            </Suspense>
          );
        }
        return (
          <>
            <AppHeader />
            <NavRoute route={routes.section} component={Section} />
          </>
        );
      }}
    />
  </div>
);

export default AppBody;
