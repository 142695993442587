import React from 'react';
import _ from 'lodash';

import FIELD_TYPES from '../../configs/fieldTypes';
import { VALUE_STATUSES } from '../../configs/import';
import { Field } from './BaseClass.js';

const findItem = (value, field) => {
  const items = field.getIn(['config', 'items']);

  if (!items) {
    return false;
  }

  value = _.trim(value);

  return items.find((radioButton) => {
    const name = _.trim(radioButton.get('name'));
    const id = radioButton.get('id');

    return _.toLower(value) == _.toLower(name) || value == id;
  });
};
export default class Radiobutton extends Field {
  constructor() {
    super();
  }

  static type = FIELD_TYPES.RADIOBUTTON;

  static components = {
    inline: require('../../components/common/dataTypes/RadiobuttonField').default,
    control: require('../../components/common/UI/ControlList/controls/Radiobutton').default,
    selector: require('../../components/Fields/selectors/RadioSelector').default,
  };

  static getComponent = (type) => Radiobutton.components[type];

  static getCanComponentExpandWidthOrHeight = (value) => {};

  static parseValue = (field, value) => {
    value = _.trim(value);

    /* парс пустого значения */
    if (Radiobutton.isEmpty(value)) {
      value = Radiobutton.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    /* парс ссырого значения */
    let item = field && findItem(value, field);
    item = item && item.get('id');

    if (item && Radiobutton.validateValue(field, item)) {
      return { value: item, status: VALUE_STATUSES.VALID };
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (field, value) => {
    if (!field) {
      return false;
    }

    if (_.isString(value) && !!parseFloat(value)) {
      const items = field.getIn(['config', 'items']);
      const item = items && items.find((radioButton) => value === radioButton.get('id'));

      if (item) {
        return true;
      }
      return false;
    }
  };

  static sortValues = (field, values) => values;

  static valueIs = (value, type) => typeof value === type;

  static isEmpty = (value) => !value;

  static calcId = (item) => item;

  static receivePossibleItems = (field, _, filters) => {
    const fieldItems = field.getIn(['config', 'items']);
    return filters || fieldItems.map((item) => item.get('id'));
  };

  static getEmptyValue = () => null;

  static removeItem = () => null;

  static compare = (value1, value2) => value1 === value2;

  static convertIdToValue = (field, id) => id;

  static setValue = (value, newValue) => newValue;

  static hasItem = (value, item) => +value === +item;

  static createComponent = (field, value, type) => {
    const Component = Radiobutton.components[type];
    return ({ containerClassName }) => {
      if (Radiobutton.isEmpty(value)) {
        return null;
      }
      return <Component config={field.get('config')} value={value} containerClassName={containerClassName} />;
    };
  };

  static convertFilterToRecordValue = (field, fieldFilters) => {
    if (!fieldFilters) return;

    let values = [];

    fieldFilters.forEach((filterValues) => {
      if (!filterValues) return;
      const filterValue = filterValues.value;
      values = _.chain(filterValue)
        .filter((i) => !!i)
        .uniq()
        .value();
    });

    return values[0];
  };

  static getDefaultValue = (field) => {
    const defaultValue = field.getIn(['config', 'defaultEmptyValue']);
    return defaultValue;
  };

  static validateRequired = (value) => {
    const empty = _.isNull(value) || value === undefined;
    return empty;
  };

  static boardWitchColor = () => false;

  static visibleRules = (v) => ({ $in: v });

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get('id');
    const fieldConfig = field.getIn(['config', 'items']);

    // sort by order id of item
    const fieldItems = {};
    fieldConfig.map((f, index) => {
      fieldItems[f.get('id')] = index;
    });

    return records.sortBy((r) => fieldItems[r.getIn(['values', fieldId])]); // value is plain
  };

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(['config', 'type']);
    switch (type) {
      default:
        return false;
    }
  };

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}
