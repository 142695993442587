import _ from 'lodash';
import i18n from './i18n';

import { TIME, DATE, WEEK, MONTH, QUARTER, YEAR } from './dateFieldSubTypes';

const dateKeys = (type) => {
  let filters = [
    {
      title: undefined,
      values: {
        FIXED: 'fixed',
        RELATIVE: 'relative',
        NULL: 'NULL_DATE',
      },
    },
    {
      title: 'this',
      values: {
        TODAY: _.includes([DATE, TIME], type) && 'TODAY_DATE',
        TILL_TODAY: _.includes([DATE, TIME], type) && 'TILL_TODAY_DATE',
        THIS_WEEK: _.includes([DATE, TIME, WEEK], type) && 'THIS_WEEK_DATE',
        // THIS_WEEK_TILL_TODAY: "THIS_WEEK_TILL_TODAY_DATE",
        // THIS_WEEK_NEXT_TODAY: "THIS_WEEK_NEXT_TODAY_DATE",
        THIS_MONTH: _.includes([DATE, TIME, MONTH], type) && 'THIS_MONTH_DATE',
        THIS_QUARTER: _.includes([DATE, TIME, QUARTER], type) && 'THIS_QUARTER_DATE',
        THIS_YEAR: _.includes([DATE, TIME, YEAR], type) && 'THIS_YEAR_DATE',
      },
    },
    {
      title: 'past',
      values: {
        YESTERDAY: _.includes([DATE, TIME], type) && 'YESTERDAY_DATE',
        TILL_YESTERDAY: _.includes([DATE, TIME], type) && 'TILL_YESTERDAY_DATE',
        LAST_WEEK: _.includes([DATE, TIME, WEEK], type) && 'LAST_WEEK_DATE',
        LAST_30_DAYS: _.includes([DATE, TIME], type) && 'LAST_30_DAYS_DATE',
        LAST_MONTH: _.includes([DATE, TIME, MONTH], type) && 'LAST_MONTH_DATE',
        LAST_QUARTER: _.includes([DATE, TIME, QUARTER], type) && 'LAST_QUARTER_DATE',
        LAST_365_DAYS: _.includes([DATE, TIME], type) && 'LAST_365_DAYS_DATE',
        LAST_YEAR: _.includes([DATE, TIME, YEAR], type) && 'LAST_YEAR_DATE',
      },
    },
    {
      title: 'future',
      values: {
        TOMORROW: _.includes([DATE, TIME], type) && 'TOMORROW_DATE',
        TOMORROW_NEXT: _.includes([DATE, TIME], type) && 'TOMORROW_NEXT_DATE',
        NEXT_WEEK: _.includes([DATE, TIME, WEEK], type) && 'NEXT_WEEK_DATE',
        NEXT_30_DAYS: _.includes([DATE, TIME], type) && 'NEXT_30_DAYS_DATE',
        NEXT_MONTH: _.includes([DATE, TIME, MONTH], type) && 'NEXT_MONTH_DATE',
        NEXT_365_DAYS: _.includes([DATE, TIME], type) && 'NEXT_365_DAYS_DATE',
        NEXT_QUARTER: _.includes([DATE, TIME, QUARTER], type) && 'NEXT_QUARTER_DATE',
        NEXT_YEAR: _.includes([DATE, TIME, YEAR], type) && 'NEXT_YEAR_DATE',
      },
    },
    {
      title: 'birthday',
      values: {
        THIS_CURRENT_DAY: _.includes([DATE], type) && 'THIS_CURRENT_DAY',
        NEXT_CURRENT_DAY: _.includes([DATE], type) && 'NEXT_CURRENT_DAY',
        THIS_CURRENT_MONTH: _.includes([DATE], type) && 'THIS_CURRENT_MONTH',
        NEXT_CURRENT_MONTH: _.includes([DATE], type) && 'NEXT_CURRENT_MONTH',
        LAST_CURRENT_DAY: _.includes([DATE], type) && 'LAST_CURRENT_DAY',
        LAST_CURRENT_MONTH: _.includes([DATE], type) && 'LAST_CURRENT_MONTH',
      },
    },
  ];

  // remove unset values
  filters = filters.map((g) => {
    g.values = _.filter(g.values, (v) => !!v);
    return g;
  });
  // remove empty groups
  filters = _.filter(filters, (g) => !!g.values.length);

  return filters;
};

const userKeys = ['$EMPTY', 'CURRENT_USER'];
const objectKeys = ['$EMPTY', '$HAS'];

const getFilters = (keys) =>
  _.map(keys, (key) => {
    if (_.isObject(key)) {
      return {
        title: key.title ? i18n.t(`filter.titles.interval.${key.title}`) : undefined,
        values: _.map(Object.keys(key.values), (keyValue) => ({
          key: key.values[keyValue],
          value: i18n.t(`filter.keys.${key.values[keyValue]}`),
        })),
      };
    }
    return {
      key,
      value: i18n.t(`filter.keys.${key}`),
    };
  });

const dateFilters = (type) => getFilters(dateKeys(type));
const userFilters = getFilters(userKeys);
const objectFilters = getFilters(objectKeys);

export { dateFilters, userFilters, objectFilters };
