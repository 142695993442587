import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Row, Select } from 'antd';
import _ from 'lodash';

import { DownOutlined } from '@ant-design/icons';
import { connect } from '../../../../../../../../StateProvider';

import routes from '../../../../../../../../../routes';
import NavRoute from '../../../../../../../../common/router/Route';
import StateRedirect from '../../../../../../../../common/router/StateRedirect';
import getLink from '../../../../../../../../common/router/getLink';

import userSettingsActions from '../../../../../../../../../actions/userSettingsActions';
import ViewActions from '../ViewActions';

import styles from './viewSelector.less';

const ViewSelector = (props) => {
  const onSelect = (viewId, option) => {
    const { catalogId, history, location } = props;

    userSettingsActions.setOption({
      catalogId,
      option: 'defaultViewId',
      value: viewId,
    });

    history.push(getLink(location, '', { viewId }));
  };

  const views = useMemo(() => {
    let viewsList = (props.views && props.views.valueSeq()) || new Immutable.Seq();

    if (viewsList && viewsList.size > 1) {
      // sort by name keeping 0 view first
      const allRecordsView = viewsList.find((v) => v.get('id') === '0');
      const newView = viewsList.find((v) => v.get('id') === '$new');

      viewsList = viewsList.filter((v) => v.get('id') !== '0' && v.get('id') !== '$new');
      viewsList = viewsList.filter((c) => !!c.get('displayName')).sortBy((c) => c.get('displayName'));
      viewsList = allRecordsView ? [allRecordsView, ...viewsList] : viewsList;
      viewsList = newView ? [...viewsList, newView] : viewsList;
      viewsList = Immutable.fromJS(viewsList);
    }

    return viewsList;
  }, [props.views]);

  const defaultView = useMemo(() => {
    let { defaultViewId, match } = props;

    defaultViewId = defaultViewId && defaultViewId.get('value');
    const matchViewId = _.get(match, ['params', 'viewId']);

    if (matchViewId) {
      return Immutable.Map({ id: matchViewId });
    }

    if (defaultViewId) {
      return Immutable.Map({ id: defaultViewId });
    }

    return Immutable.Map({ id: '0' });
  }, [props.defaultViewId, props.match]);

  return (
    <Row className={cn(styles.viewsSelectorContainer, props.className)}>
      {/* редирект на новый вид */}
      {props.isNewViewExist && !(props.viewId === '$new') ? (
        <StateRedirect route={routes.view} params={{ viewId: '$new' }} />
      ) : null}

      {/* редирект на вид по умолчанию, если выбран  несуществующий вид */}
      <NavRoute route={routes.view}>
        {({ match }) => {
          if (
            props.viewsLoaded &&
            props.views &&
            !props.views.find((view) => view.get('id') === _.get(match, ['params', 'viewId']))
          ) {
            return (
              <StateRedirect
                route={routes.view}
                params={{
                  viewId: defaultView && defaultView.get('id'),
                }}
              />
            );
          }
          return null;
        }}
      </NavRoute>

      <NavRoute route={routes.view}>
        {({ match }) => {
          const viewId = _.get(match, ['params', 'viewId']);
          return (
            <Select
              value={viewId}
              defaultValue={viewId}
              className={styles.viewsSelector}
              showSearch
              suffixIcon={<DownOutlined />}
              onSelect={onSelect}
            >
              {views
                .map((view) => (
                  <Select.Option key={view.get('id')} title={view.get('displayName')}>
                    {view.get('displayName')}
                  </Select.Option>
                ))
                .toJS()}
            </Select>
          );
        }}
      </NavRoute>

      <ViewActions
        sceneId={props.sceneId}
        catalogId={props.catalogId}
        viewId={props.viewId}
        catalog={props.catalog}
        className={styles.viewActions}
      />
    </Row>
  );
};

ViewSelector.propTypes = {
  sceneId: PropTypes.string.isRequired,
  catalogId: PropTypes.string.isRequired,
  viewId: PropTypes.string.isRequired,
  views: ImmutablePropTypes.map.isRequired,
};

export default withTranslation()(
  connect(
    withRouter(ViewSelector),
    {
      userSettings: ['userSettings', 'catalogs'],
      catalogsHistory: ['catalogsHistory'],
      scenes: ['scenes'],
    },
    (props, { userSettings, scenes }) => {
      const scene = scenes.get(props.sceneId);
      const { catalog } = props;
      const { viewId } = props;
      const views = catalog && catalog.get('views') && catalog.get('views').merge(scene && scene.get('views'));
      const viewsLoaded = catalog && catalog.get('viewsLoaded');

      const filtersChanged = (scene && scene.getIn(['views', viewId, 'filtersChanged'])) || false;

      const defaultViewId = userSettings.getIn([props.catalogId, 'options', 'defaultViewId']);

      const isNewViewExist = views && views.some((view) => view.get('id') === '$new');

      return {
        scene,
        views,
        viewsLoaded,
        filtersChanged,
        isNewViewExist,
        defaultViewId,
        ...props,
      };
    },
  ),
);
