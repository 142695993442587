import _ from 'lodash';
import { message } from 'antd';

import i18n from '../../configs/i18n';

const recordMixinDeleting = {
  deleteRecord(params, actionParams) {
    const { silent = false } = actionParams;
    if (silent) return;
    /* Тихое удаление, то есть нам не нужно ничего делать для того чтобы не перегружать 
    клиента, остальных случаях удаления код ниже будет выполняться */
    this.setIn(['records', params.catalogId, params.recordId, 'deleting'], true);
    this.setIn(['records', params.catalogId, params.recordId, 'deleteError'], false);
    this.changed();
  },

  deleteRecordCompleted(result, params, data, query, res, actionParams) {
    if (params.recordId === '$all') {
      const records = this.getIn(['records', params.catalogId]);
      records &&
        records.forEach((record) => {
          const recordId = record.get('id');
          const { catalogId } = params;
          const { sceneId } = actionParams;

          this.modifyItselfInLinks({ catalogId, recordId, sceneId }, (linkedRecord) =>
            linkedRecord.set('isRemoved', true),
          );

          this.clearRecordStore(catalogId, recordId);
        });
      this.deleteIn(['records', params.catalogId]);
    } else {
      const recordKey = ['records', params.catalogId, params.recordId];

      this.modifyItselfInLinks(params, (linkedRecord) => linkedRecord.set('isRemoved', true));

      this.clearRecordStore(params.catalogId, params.recordId);
      this.deleteIn([...recordKey]);
    }

    this.changed();
  },

  deleteRecordFailed(err, params, data, query, actionParams) {
    const { silent = false, onFailed } = actionParams;
    const { alert } = require('../../components/common/Modal');
    const errText = _.isObject(err) && err.text ? err.text : '';
    if (!silent) {
      this.setIn(['records', params.catalogId, params.recordId, 'deleting'], false);
      this.setIn(['records', params.catalogId, params.recordId, 'deleteError'], errText || true);
      this.changed();
    }

    const isAccessDenied = err && err.status == 403;
    const notFound = err && err.status == 403;
    const serverError = err ? err.status && err.status[0] === 5 : false;

    let text;

    if (isAccessDenied) {
      /* можно разные текста использовать */
      text = errText || i18n.t('modals.denied.record.delete.text');
      onFailed ? onFailed(params.catalogId, params.recordId, text) : message.error(text);
    } else if (notFound) {
      text = errText || i18n.t('modals.deleteRecordError.text');
      onFailed ? onFailed(params.catalogId, params.recordId, text) : message.error(text);
    } else if (serverError) {
      text = errText || i18n.t('modals.deleteRecordError.text');
      onFailed ? onFailed(params.catalogId, params.recordId, text) : message.error(text);
    } else {
      text = {
        headerText: (err && err.title) || i18n.t('modals.deleteRecordError.headerText'),
        text: errText || i18n.t('modals.deleteRecordError.text'),
        okText: i18n.t('modals.deleteRecordError.okText'),
      };
      onFailed
        ? onFailed(params.catalogId, params.recordId, text)
        : setTimeout(() => {
            alert(text);
          }); // need async to show alert OVER record modal (if is opens to edit in cards, calendar, ...);
    }
  },
};

export default recordMixinDeleting;
