import React from 'react';
import Immutable, { fromJS } from 'immutable';
import _ from 'lodash';
import { Checkbox } from 'antd';

import importActions from '../../actions/importActions';
import FIELD_TYPES from '../../configs/fieldTypes';
import { VALUE_STATUSES } from '../../configs/import';
import appState from '../../appState';
import { Field } from './BaseClass.js';

const delimiters = [',', ';'];

const splitValue = (values, delimiter) => {
  values = _.chain(values).split(delimiter).map(_.trim).value();

  return Immutable.List(values);
};

const getItems = async (value, field, catalogId) => {
  const fieldId = field.get('id');

  const params = {
    catalogId,
    fieldId,
    title: value,
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      importActions
        .loadLinkedItems(params)
        .then((res) => resolve(res))
        .catch((err) => console.error(err));
    }, 25);
  });
};

const findItem = async (value, field, catalogId) => {
  const items = await getItems(value, field, catalogId);

  const itemData =
    items &&
    items.find((linkedRecord) => {
      const recordTitle = _.get(linkedRecord, 'recordTitle');
      const recordId = _.get(linkedRecord, 'recordId');
      const key = _.get(linkedRecord, 'key');
      const text = _.get(linkedRecord, 'text');
      return (
        _.toLower(value) == _.toLower(recordTitle) ||
        _.toLower(value) == _.toLower(text) ||
        value == recordId ||
        value == key
      );
    });

  if (itemData) {
    return Immutable.fromJS([
      {
        ...itemData.item,
        catalogIcon: itemData.icon,
      },
    ]);
  }

  return false;
};

const uniqComporator = (arrVal, othVal) => {
  const firstKey = `${arrVal.catalogId}_${arrVal.recordId}`;
  const secondKey = `${othVal.catalogId}_${othVal.recordId}`;

  return firstKey == secondKey;
};

const uniqObjectValues = (values) => _.uniqWith(values, uniqComporator);

class OBJECT extends Field {
  constructor() {
    super();
  }

  static type = FIELD_TYPES.OBJECT;

  static components = {
    inline: require('../../components/common/dataTypes/ObjectField').default,
    control: require('../../components/common/UI/ControlList/controls/Object').default,
    selector: require('../../components/Fields/selectors/ObjectSelector').default,
  };

  static parseValue = async (field, value, catalogId) => {
    value = _.trim(value);

    /* попытка распарсить пустое значение */
    if (_.isEmpty(value)) {
      value = OBJECT.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    if (field) {
      const multiselect = field && field.get(['config', 'multiselect']);

      /* попытка распарсить мультиселект */
      if (multiselect) {
        /* как заданное через разделители */
        let valuesByDelimetr = {};

        for (const delimiter of delimiters) {
          /* разделение значения по разделителям */
          const splitedValuesByDelimetr = splitValue(value, delimiter);

          if (splitedValuesByDelimetr && !splitedValuesByDelimetr.isEmpty()) {
            let validatedValues = Immutable.List([]);

            /* для каждого значения проводим валидацию, это нужно для "мягкого" режима отображения */
            for (const value of splitedValuesByDelimetr) {
              const item = await findItem(value, field, catalogId);

              if (OBJECT.validateValue(field, item)) {
                validatedValues = validatedValues.concat(item);
              }
            }
            /* заполняем объект удачно распаршенными значениями */
            if (validatedValues && !validatedValues.isEmpty()) {
              valuesByDelimetr[delimiter] = validatedValues;
            }
          }
        }

        valuesByDelimetr = _.sortBy(valuesByDelimetr, (values) => values.size);
        const mostMatchesValues = _.last(valuesByDelimetr);

        if (mostMatchesValues && !mostMatchesValues.isEmpty()) {
          return { value: mostMatchesValues, status: VALUE_STATUSES.VALID };
        }
      } else {
        /* попытка распарсить не мультиселект */
        const item = await findItem(value, field, catalogId);

        if (OBJECT.validateValue(field, item)) {
          return { value: item, status: VALUE_STATUSES.VALID };
        }
      }
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (field, value) => {
    if (Immutable.List.isList(value)) {
      return true;
    }

    if (!field) {
      return false;
    }
  };

  static getComponent = (type) => OBJECT.components[type];

  static getCanComponentExpandWidthOrHeight = (value) => {};

  static isEmpty = (value) => !(value && value.size);

  static calcId = (value) => `${value.get('catalogId')}:${value.get('recordId')}`;

  static setValue = (values, newValues) => {
    const res = newValues ? values.push(newValues) : values;
    return res;
  };

  static hasItem = (value, id) =>
    value &&
    value.some((item) => {
      const [catalogId, recordId] = id.split(':');
      return item.get('catalogId') === catalogId && item.get('recordId') === recordId;
    });

  static sortValues = (field, values, sortType) => {
    values = _.sortBy(values.toJS ? values.toJS() : values, (item) => item.recordTitle);

    return Immutable.List(values.map((value) => fromJS(value)));
  };

  static removeItem = (values, id) => {
    const [catalogId, recordId] = id.split(':');
    return values.filter((v) => !(v.get('recordId') === recordId && v.get('catalogId') === catalogId));
  };

  static getEmptyValue = () => Immutable.List();

  static convertIdToValue = (field, id, records, filters) => {
    const [catalogId, recordId] = id.split(':');
    const values = OBJECT.receivePossibleItems(field, records, filters);
    const value = values.find((v) => v.get('recordId') === recordId && v.get('catalogId') === catalogId);
    return value || null;
  };

  static receivePossibleItems = (field, records, filters) => {
    let items = Immutable.List();
    records &&
      records.forEach((record) => {
        const addItems = record.getIn(['values', field.get('id')]);
        addItems &&
          addItems.forEach((addItem) => {
            if (!items.some((item) => OBJECT.compareItem(item, addItem))) {
              items = items.push(addItem);
            }
          });
      });
    filters &&
      filters.forEach((filterItem) => {
        if (!items.some((item) => OBJECT.compareItem(item, filterItem))) {
          items = items.push(filterItem);
        }
      });
    return items;
  };

  static valueIs = (value, type) => typeof value === type;

  static createComponent = (field, value, type) => {
    const Component = OBJECT.components[type];
    value = value.toJS ? value : fromJS(value);
    value = _.isArray(value.toJS()) ? value : Immutable.List([value]);

    return ({ containerClassName }) => {
      if (OBJECT.isEmpty(value)) {
        return null;
      }
      return <Component config={field.get('config')} value={value} containerClassName={containerClassName} />;
    };
  };

  static compareItem = (item1, item2) =>
    item1.get('recordId') === item2.get('recordId') && item1.get('catalogId') === item2.get('catalogId');

  static compare = (value1, value2) => {
    if (value1 && value1.toJS && value2 && value2.toJS) {
      return value1.equals(value2);
    }

    return _.isEqual(value1, value2);
  };

  static convertFilterToRecordValue = (field, fieldFilters, catalogs, user) => {
    if (!fieldFilters) return;

    let values = [];

    // check if field doesnt allow to select from existin records
    if (!field.getIn(['config', 'enableSelect'])) return;

    fieldFilters.forEach((filterValues) => {
      if (_.isEmpty(filterValues)) return;

      const filterValue = filterValues.value;
      filterValue.forEach((value) => {
        // linked record is user role
        if (value.catalogId == 'USER_FIELD') {
          const userField = user.get('subjects').find((userSubject) => userSubject.get('userAttr') == value.recordId);

          value.catalogId = userField.get('valueCatalogId');
          value.recordTitle = `#${userField.get('valueRecordId')}`;
          value.recordId = userField.get('valueRecordId');
          values.push(value);
        } else if (value.catalogId && value.recordId) {
          values.push(value);
        }
      });
    });
    values = uniqObjectValues(values);
    return values;
  };

  static getDefaultValue = (field) => {
    const defaultValue = field.getIn(['config', 'defaultEmptyValue']);
    return defaultValue;
  };

  static validateRequired = (value) => _.isUndefined(value) || _.isNull(value) || _.isEmpty(value);

  static boardWitchColor = () => true;

  static visibleRules = (value) => {
    const v = value;
    let newV = [];
    const userSubjects = appState.getIn(['user', 'subjects']);
    v.forEach((i) => {
      // map user subjects to filter
      if (i.catalogId == 'USER_FIELD') {
        userSubjects &&
          userSubjects.forEach((s) => {
            if (s.get('userAttr') == i.recordId) {
              newV.push({
                catalogId: s.get('valueCatalogId'),
                recordId: s.get('valueRecordId'),
              });
            }
          });
      } else if ((i.catalogId == '$users' || i.catalogId == '3') && i.recordId == 'CURRENT_USER') {
        newV.push({
          catalogId: '$users',
          recordId: String(appState.getIn(['user', 'id'])),
        });
      } else if (String(i.catalogId).toLowerCase() == '$empty' || String(i.recordId).toLowerCase() == '$empty') {
        newV.push({
          catalogId: '$empty',
        });
      } else {
        // recordId can be empty (for any record from catalog)
        newV.push(_.pick(i, ['catalogId', 'recordId']));
      }
    });
    newV = { $in: newV };

    return newV;
  };

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get('id');
    // sort by linked record title
    return records.sortBy((r) => r.getIn(['values', fieldId, 0, 'recordTitle']));
  };

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(['config', 'type']);
    switch (type) {
      default:
        return false;
    }
  };

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}

export default OBJECT;
