import React from 'react';
import { useTranslation } from 'react-i18next';

import LinkedItem from '../../../../LinkedItem/index';
import LoadingSpin from '../../../../../LoadingSpin';

import styles from '../../controls.less';

const ListItem = (props) => {
  const { item, onRemoveItem, onRestoreItem, params, sceneId } = props;
  const { t } = useTranslation();

  const itemRemovableProperty = item.getIn(['item', 'removable']);
  const canRestore = item.get('canRestore');
  const itemData = item.get('item');
  const removed = itemData && itemData.get('isRemoved');

  if (canRestore) {
    return (
      <LinkedItem
        key={item.get('key')}
        removable={itemRemovableProperty === false ? itemRemovableProperty : props.editable && !props.apiOnly}
        title={t('record.fields.object.restore')}
        item={{
          icon: 'transfers-70',
          text: item.get('text') || t('record.newRecordCreating'),
        }}
        sceneId={sceneId}
        // className={props.className}
        classNameItem={styles.restoreItemText}
        classNameColor={styles.restoreItemColor}
        classNameItemColor={styles.restoreItemColor}
        onClickRemove={() => onRemoveItem && onRemoveItem(item)}
        onClick={onRestoreItem}
      />
    );
  }
  return (
    <LinkedItem
      key={item.get('key')}
      removed={removed}
      sceneId={sceneId}
      removable={itemRemovableProperty === false ? itemRemovableProperty : props.editable && !props.apiOnly}
      item={
        !item.get('text')
          ? {
              icon: <LoadingSpin />,
              text: t('loadingText'),
            }
          : item.toJS()
      }
      onClickRemove={() => props.onRemoveItem && props.onRemoveItem(item)}
      params={params}
    />
  );
};

export default ListItem;
