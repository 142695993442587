import React from 'react';

import Icon from '../../../../../common/UI/Icon';

import styles from '../companyInfo.less';

const UserMenuHelper = ({ helpers }) => (
  <div className={styles.licenseHelperContainer}>
    {helpers.map((helper) => (
      <div key={helper.id} className={styles.licenseHelperItems} onClick={helper?.func}>
        <Icon type={helper.iconType} className={styles.licenseHelperIcon} />
        <span>{helper.text}</span>
      </div>
    ))}
  </div>
);

export default UserMenuHelper;
