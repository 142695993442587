// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page-error-module__pageErrorWrapper___DIGVN {\n  width: 100vw;\n  height: 100vh;\n  display: inline-block;\n  margin: 0 auto;\n  display: grid;\n  place-items: center;\n}\n.page-error-module__pageErrorWrapper___DIGVN .page-error-module__pageErrorContent___rZqkb {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: space-evenly;\n  gap: 15px;\n}\n.page-error-module__pageErrorWrapper___DIGVN .page-error-module__pageErrorContent___rZqkb .page-error-module__errorDetails___s9Gyg {\n  margin-bottom: 10px;\n}\n.page-error-module__pageErrorWrapper___DIGVN .page-error-module__pageErrorContent___rZqkb .page-error-module__pageErrorTitle___lpCYj {\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/UI/PageError/page-error.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,qBAAA;EACA,cAAA;EAEA,aAAA;EACA,mBAAA;AAAF;AAPA;EAUI,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,6BAAA;EACA,SAAA;AAAJ;AAdA;EAiBM,mBAAA;AAAN;AAjBA;EAqBM,mBAAA;AADN","sourcesContent":[".pageErrorWrapper {\n  width: 100vw;\n  height: 100vh;\n  display: inline-block;\n  margin: 0 auto;\n\n  display: grid;\n  place-items: center;\n\n  .pageErrorContent {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    justify-content: space-evenly;\n    gap: 15px;\n\n    .errorDetails {\n      margin-bottom: 10px;\n    }\n\n    .pageErrorTitle {\n      margin-bottom: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageErrorWrapper": "page-error-module__pageErrorWrapper___DIGVN",
	"pageErrorContent": "page-error-module__pageErrorContent___rZqkb",
	"errorDetails": "page-error-module__errorDetails___s9Gyg",
	"pageErrorTitle": "page-error-module__pageErrorTitle___lpCYj"
};
export default ___CSS_LOADER_EXPORT___;
