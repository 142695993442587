import React, { useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TIME, DATE, WEEK, MONTH, QUARTER, YEAR } from '../../../configs/dateFieldSubTypes';
import { formats, subFormats, timeFormats } from '../../../configs/datePickerFormats';
import styles from './selectors.less';
import WrappedDatePicker from '../../common/UI/WrappedDatePicker';

const API_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
const MOMENT_TIME_FORMAT = 'HH:mm';

const DateSelector = (props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(props.autoFocus);

  const onChange = (value) => {
    if (type === WEEK) {
      value.startOf('week');
    } else if (type === MONTH) {
      value.startOf('month');
    } else if (type === QUARTER) {
      value.startOf('quarter');
    } else if (type === YEAR) {
      value.startOf('year');
    }
    value = value !== null ? moment(value).format(API_FORMAT) : value;
    props.onChange && props.onChange(value);
  };

  const onOpenChange = (open) => {
    setOpen(open);
    props.onEndEditing();
  };

  const type = props.config.get('type');
  const withTime = type === TIME;
  const { value } = props;
  const momentValue = moment(value || '');
  const dateIsValid = momentValue.isValid();

  const format = formats[type] || 'L';
  const subFormat = subFormats[type] || [];

  const subLocaleFormat = i18n.language === 'ru' ? 'DD,MM,YYYY' : 'MM,DD,YYYY';

  const possibleFormats = [format, ...subFormat, subLocaleFormat];

  if (type === TIME) {
    const formatsWithTime = possibleFormats.reduce(
      (accum, format) => accum.concat(timeFormats.map((timeFormat) => `${format} ${timeFormat}`)),
      [],
    );
    possibleFormats.unshift(...formatsWithTime);
  }

  // picker
  const pickers = {
    [TIME]: 'date',
    [DATE]: 'date',
    [WEEK]: 'week',
    [MONTH]: 'month',
    [QUARTER]: 'quarter',
    [YEAR]: 'year',
  };
  const picker = pickers[type] || 'date';

  return (
    <WrappedDatePicker
      picker={picker}
      showTime={withTime && { format: MOMENT_TIME_FORMAT, minuteStep: 5 }}
      value={dateIsValid ? momentValue : null}
      format={possibleFormats}
      placeholder={t(`fieldTypes.date.placeholder.${withTime ? DATE : type}`)}
      className={cn(styles.datePicker, props.className)}
      popupClassName={styles.datePickerPopup}
      open={open}
      autoFocus={props.autoFocus}
      allowClear={false}
      onChange={onChange}
      onSelect={onChange}
      onOpenChange={onOpenChange}
    />
  );
};

DateSelector.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
};

export default DateSelector;
