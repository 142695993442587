import React from 'react';
import Immutable from 'immutable';
import _ from 'lodash';

import FIELD_TYPES from '../../configs/fieldTypes';
import { VALUE_STATUSES } from '../../configs/import';
import { Field } from './BaseClass.js';

const delimiters = [',', ';'];

const splitValue = (values, delimiter) => {
  if (_.isString(values)) {
    values = values.replaceAll(/[\{\}\[\]]/g, '');
    values = _.chain(values).split(delimiter).map(_.trim).value();
  }

  return Immutable.List(values);
};

const findItem = (value, field) => {
  const items = field.getIn(['config', 'items']);

  if (!items) {
    return false;
  }

  value = _.trim(value);

  return items.find((status) => {
    const name = _.trim(status.get('name'));
    const text = _.trim(status.get('text'));
    const id = status.get('id');

    return _.toLower(value) == _.toLower(name) || _.toLower(value) == _.toLower(text) || value == id;
  });
};

export default class Dropdown extends Field {
  constructor() {
    super();
  }

  static type = FIELD_TYPES.DROPDOWN;

  static components = {
    inline: require('../../components/common/dataTypes/CategoryField').default,
    control: require('../../components/common/UI/ControlList/controls/CategoryList').default,
    selector: require('../../components/Fields/selectors/CategorySelector').default,
  };

  static getComponent = (type) => Dropdown.components[type];

  static getCanComponentExpandWidthOrHeight = (value) => {};

  static parseValue = (field, value) => {
    value = _.trim(value);

    /* попытка распарсить пустое значение */
    if (_.isEmpty(value)) {
      value = Dropdown.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    if (field) {
      const multiselect = field.getIn(['config', 'multiselect']);

      /* попытка распарсить мультиселект */
      if (multiselect) {
        /* как заданное через разделители */
        let valuesByDelimetr = {};

        _.forEach(delimiters, (delimiter) => {
          /* разделение значения по разделителям */
          const splitedValuesByDelimetr = splitValue(value, delimiter);
          if (splitedValuesByDelimetr && !splitedValuesByDelimetr.isEmpty()) {
            /* для каждого значения проводим валидацию, это нужно для "мягкого" режима отображения */
            const validatedValues = splitedValuesByDelimetr
              .map((value) => {
                const item = findItem(value, field);
                if (item && Dropdown.validateValue(field, Immutable.List([item]))) {
                  return item.get ? Immutable.fromJS(item.get('id')) : null;
                }
                return null;
              })
              .filter((value) => !_.isNull(value));

            /* заполняем объект удачно распаршенными значениями */
            if (validatedValues && !validatedValues.isEmpty()) {
              valuesByDelimetr[delimiter] = validatedValues.filter(
                (value, index) => validatedValues.indexOf(value) === index,
              ); // uniq
            }
          }
        });

        valuesByDelimetr = _.sortBy(valuesByDelimetr, (values) => values.size);
        const mostMatchesValues = _.last(valuesByDelimetr);

        if (mostMatchesValues && !mostMatchesValues.isEmpty()) {
          return { value: mostMatchesValues, status: VALUE_STATUSES.VALID };
        }
      } else {
        /* попытка распарсить не мультиселект */
        const item = findItem(value, field);
        if (item && Dropdown.validateValue(field, Immutable.List([item]))) {
          value = Immutable.List([item.get('id')]);
          return { value, status: VALUE_STATUSES.VALID };
        }
      }
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (field, value) => {
    if (Immutable.List.isList(value)) {
      return true;
    }

    if (!field) {
      return false;
    }
  };

  static setValue = (values, newValues) => {
    if (newValues) {
      return _.isArray(newValues) || (newValues.toJS && _.isArray(newValues.toJS()))
        ? values.concat(newValues)
        : values.push(newValues);
    }
    return values;
  };

  static hasItem = (value, item) => value && value.some((i) => i === item);

  static valueIs = (value, type) => typeof value === type;

  static convertIdToValue = (field, id) => id;

  static removeItem = (values, value) => values.filter((v) => v !== value);

  static sortValues = (field, values, sortType) => values;

  static calcId = (possibleItem) => possibleItem;

  static getEmptyValue = () => Immutable.List();

  static receivePossibleItems = (field, records, filters) => {
    if (filters) {
      let values = records.map((record) => record.getIn(['values', field.get('id')]));
      values = values.filter((value) => value.some((v) => filters.includes(v)));
      let items = Immutable.List();
      values.forEach((arrValue) => {
        arrValue.forEach((value) => {
          if (!items.includes(value)) {
            items = items.push(value);
          }
        });
      });
      return items;
    }
    return field.getIn(['config', 'items']).map((item) => item.get('id'));
  };

  static isEmpty = (value) => !(value && value.size);

  static compare = (value1, value2) => {
    if (value1 && value1.toJS && value2 && value2.toJS) {
      return value1.equals(value2);
    }

    return _.isEqual(value1, value2);
  };

  static createComponent = (field, value, type) => {
    const Component = Dropdown.components[type];

    value = _.isObject(value) ? value : Immutable.List([value]);

    return ({ containerClassName }) => {
      if (Dropdown.isEmpty(value)) {
        return null;
      }
      return <Component config={field.get('config')} value={value} containerClassName={containerClassName} />;
    };
  };

  static convertFilterToRecordValue = (field, fieldFilters) => {
    if (!fieldFilters) return;

    let values = [];

    fieldFilters.forEach((filterValues) => {
      if (!filterValues) return;
      const filterValue = filterValues.value;
      values = _.chain(filterValue)
        .filter((i) => !!i)
        .uniq()
        .value();
    });

    return values;
  };

  static getDefaultValue = (field) => {
    const defaultValue = field.getIn(['config', 'defaultEmptyValue']);
    return defaultValue;
  };

  static validateRequired = (value) => {
    const empty = !value || (_.isArray(value) && value.length == 0);
    return empty;
  };

  static boardWitchColor = () => false;

  static visibleRules = (v) => ({ $in: v });

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get('id');
    const fieldConfig = field.getIn(['config', 'items']);

    // sort by order id of item
    const fieldItems = {};
    fieldConfig.map((f, index) => {
      fieldItems[f.get('id')] = index;
    });
    return (records = records.sortBy((r) => fieldItems[r.getIn(['values', fieldId, 0])])); // value is array;
  };

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(['config', 'type']);
    switch (type) {
      default:
        return false;
    }
  };

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}
