import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { Layout, Input } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from '../../UI/Icon';
import LoadingSpin from '../../LoadingSpin';
import { useGetTutorials, useHelperNavigate } from './hooks';
import { TipList, HelperDetail, HelperList } from './ui';
import uiActions from '../../../../actions/uiActions';

import styles from './styles/helper.less';

const HelperContent = ({ visible, code = '', privilegeCode = 'user' }) => {
  const { i18n, t } = useTranslation();
  const helperSearchRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchHelpText, setSearchHelpText] = useState('');

  const { error, loading, tutorials, getLazyTutorialItems } = useGetTutorials(privilegeCode, i18n.language);

  const { currentTutorial, showList, switchToTutorial, toAllTutorials, setShowList, setCurrentTutorial } =
    useHelperNavigate(tutorials);

  const onHelpSearchChange = (e) => {
    setSearchHelpText(e.target.value);
  };

  useEffect(() => {
    helperSearchRef && helperSearchRef.current && helperSearchRef.current.focus();

    if (code) {
      switchToTutorial(code);
    } else {
      uiActions.unsetHelperCode();
    }

    return () => {
      /**
       * Clear codes after closing popover
       * It's important enough, becouse in other case u will face problem
       * with code existing even after it was shown
       */
      clearState();
      uiActions.unsetHelperCode();
    };
  }, []);

  const clearState = () => {
    setShowList(true);
    setSearchHelpText('');
    setCurrentTutorial(null);
    setModalVisible(false);
    setFilteredItems([]);
  };

  useEffect(() => {
    if (visible) {
      clearState();
      if (code) {
        switchToTutorial(code);
      }
    } else {
      // Unset code on unmount
      uiActions.unsetHelperCode();
    }
  }, [visible]);

  useEffect(() => {
    if (code) {
      setShowList(false);
      switchToTutorial(code);
    } else {
      uiActions.unsetHelperCode();
      setShowList(true);
    }
  }, [tutorials, code]);

  useEffect(() => {
    const items = getLazyTutorialItems;
    const filtered = items.filter(
      (item) => item.title && searchHelpText && item.title.toLowerCase().includes(searchHelpText.toLowerCase()),
    );
    setFilteredItems(filtered);
  }, [searchHelpText]);

  const toggleVisibility = () => {
    setModalVisible(!modalVisible);
  };

  if (loading) {
    return (
      <Layout className={styles.helperContainer}>
        <Layout.Content style={{ width: '100%' }} className={styles.noData}>
          <LoadingSpin spin={loading} /> {t('loadingText')}
        </Layout.Content>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout className={styles.helperContainer}>
        <Layout.Content style={{ width: '100%' }} className={styles.noData}>
          <p>{t('helper.notFoundByLanguage')}</p>
        </Layout.Content>
      </Layout>
    );
  }

  return (
    <Layout className={styles.helperContainer}>
      <Layout.Content className={styles.helperHeader}>
        {currentTutorial && !showList ? (
          <div onClick={() => toAllTutorials()} className={cn(styles.linkExpanded)}>
            <Icon type="icon arrows-chevron-medium-3-01" /> {t('helper.all')}
          </div>
        ) : (
          <Input
            ref={helperSearchRef}
            placeholder={t('helper.searchText')}
            allowClear
            className={styles.helpSearch}
            value={searchHelpText}
            onChange={onHelpSearchChange}
          />
        )}
      </Layout.Content>
      <Layout.Content style={{ width: '100%' }} className={styles.scrollable}>
        {searchHelpText && _.isEmpty(filteredItems) && (
          <div className={cn(styles.helperBody, styles.notFound)}>{t('helper.notFound')}</div>
        )}

        {!_.isEmpty(filteredItems) && (
          <div className={styles.helperBody}>
            <TipList items={filteredItems} title={t('helper.searchResults')} switchToTutorial={switchToTutorial} />
          </div>
        )}

        {currentTutorial && !showList && (
          <HelperDetail
            toAllTutorials={toAllTutorials}
            tutorial={currentTutorial}
            toggleVisibility={toggleVisibility}
            modalVisible={modalVisible}
            switchToTutorial={switchToTutorial}
          />
        )}
        {showList && !searchHelpText && _.isEmpty(filteredItems) && (
          <HelperList tutorials={tutorials} switchToTutorial={switchToTutorial} />
        )}
      </Layout.Content>
    </Layout>
  );
};

export default HelperContent;
