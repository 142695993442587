import React from 'react';
import { Carousel } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import VideoPlayer from '../../../../../VideoPlayer';
import Icon from '../../../../../Icon';
import styles from './video.less';
import ButtonClose from '../../../../../ButtonClose';

const VideoViewer = (props) => {
  // by default a half of full volume
  const { files, readOnly, removeFn } = props;
  const defaultVolume = 0.5;
  const onRemove = (file) => removeFn(file);

  return (
    <div className={cn(styles.videoContainer)}>
      {files.length > 0 && (
        <Carousel>
          {files.map((file, index) => (
            <div key={index} className={cn(styles.videoItem)}>
              <div className={cn(styles.videoTitle)}>
                <Icon type="icon arrows-triangle-big-circle-4-01" className={cn(styles.playButton)} />
                <div className={cn(styles.videoFileName)}>{file.title}</div>
                {removeFn && !readOnly && (
                  <ButtonClose onClick={() => onRemove(file)} small className={cn(styles.removeVideoButton)} />
                )}
              </div>
              <VideoPlayer
                className={cn(styles.videoPlayer)}
                volume={defaultVolume}
                controls={!readOnly}
                url={file.url}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

VideoViewer.viewerName = 'VideoViewer';
VideoViewer.priority = 4;

export default VideoViewer;
